<template>
  <!--Start Page Banner-->
  <div class="page-banner-area bg-1">
    <div class="container">
      <div class="page-banner-content">
        <h1>Gallery</h1>
        <ul>
          <li><a href="/">Home</a></li>
          <li>Gallery</li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Page Banner-->

  <!--Start Gallery Area-->
  <div class="courses-area">
    <div class="alumni-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div v-for="(item, index) in data" :key="index">
            <div class="section-title pt-90" style="max-width: 100%">
              <h2>{{ item.event_title }}</h2>
              <p>{{ item.event_description }}</p>
            </div>
            <div class="row">
              <div class="single-courses-card style2 col" style="margin: 1rem">
                <div class="courses-img">
                  <a href="#"
                    ><img
                      style="width: 480px; height: fit-content; padding: 10px"
                      :src="this.baseUrl + item.photo_1_pic_path"
                      alt="Image"
                  /></a>
                </div>
              </div>
              <div class="single-courses-card style2 col" style="margin: 1rem">
                <div class="courses-img">
                  <a href="#"
                    ><img
                      style="width: 480px; height: fit-content; padding: 10px"
                      :src="this.baseUrl + item.photo_2_pic_path"
                      alt="Image"
                  /></a>
                </div>
              </div>
              <div class="single-courses-card style2 col" style="margin: 1rem">
                <div class="courses-img">
                  <a href="#"
                    ><img
                      style="width: 480px; height: fit-content; padding: 10px"
                      :src="this.baseUrl + item.photo_3_pic_path"
                      alt="Image"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Gallery Area-->

  <!-- Start Bottom Connect Area -->
  <div class="alumni-interview-area pb-100">
    <div class="container">
      <div class="interview-content ptb-100">
        <div class="interview">
          <router-link class="default-btn btn" to="/editorialboard"
            >Editorial Board</router-link
          >
          <router-link class="default-btn btn" to="/applynow"
            >Apply Now</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <!--End Bottom Connect Area -->
</template>
<script>
import axios from "axios";
export default {
  name: "GalleryView",
  data() {
    return {
      data: null,
      right_info: "",
      left_info: "",
      baseUrl: "https://rdc.eshala.co.in/",
    };
  },
  methods: {
    async fetchData() {
      axios
        .post(this.baseUrl + "api/webservice/getphotosforgallery")
        .then((response) => {
          this.data = response.data.eventList;
          console.log(this.data);
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          alert("Unstable Internet. Try Later");
        });

      // const responseValue = await fetch(baseUrl);
      // this.data = await responseValue.json();

      // console.log(this.data);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
