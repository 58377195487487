<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Teaching Staff</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Academics</li>
                    <li>Teaching Staff</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Teaching Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                    <div v-for="(item, index) in data" :key="index" class="col-lg-6">
                        <div class="alumni-left-content" v-if="index % 2">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img"><img :src="item.photo" alt="Image" style="width:80%;">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>{{ item.name }}</h3>
                                            <div class="location">
                                                <p><i
                                                        class="ri-medal-2-fill"></i><strong></strong>{{ item.designation }}
                                                </p>
                                            </div>
                                            <div class="location">
                                                <p><i
                                                        class="ri-graduation-cap-line"></i><strong></strong>{{ item.qualification }}
                                                </p>
                                            </div>
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong></strong>{{ item.experience }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-else class="alumni-right-content" >
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img"><img :src="item.photo" alt="Image" style="width:80%;">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>{{ item.name }}</h3>
                                            <div class="location">
                                                <p><i
                                                        class="ri-medal-2-fill"></i><strong></strong>{{ item.designation }}
                                                </p>
                                            </div>
                                            <div class="location">
                                                <p><i
                                                        class="ri-graduation-cap-line"></i><strong></strong>{{ item.qualification }}
                                                </p>
                                            </div>
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong></strong>{{ item.experience }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <!--End Teaching Area-->


    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/nonteachingstaff" class="default-btn btn">Non-Teaching<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->


</template>

<script>
export default {
    name: "TeachingStaffView",
    data() {
        return {
            data: null,
            right_staff_info: '',
            left_staff_info: ''
        };
    },
    methods: {
        async fetchData() {

            const responseValue = await fetch("json/teaching.json");
            this.data = await responseValue.json();
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>
