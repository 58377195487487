<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Leadership Message</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>About</li>
                    <li>Leadership Message</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!-- MAIN start -->

    <section class="team-area-content">
        <div class="container"> <!-- Lower Content -->
            <div class="lower-content">
                <div class="row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <div class="team-img-box"> <img width="460" height="500"
                                src="/vf/images/general/leadership/1.png"
                                class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt=""
                                decoding="async" fetchpriority="high"></div>
                    </div>
                    <div class="text-column col-lg-7 col-md-12 col-sm-12">
                        <div class="s-about-content pl-30 wow fadeInRight" data-animation="fadeInRight" data-delay=".2s"
                            style="visibility: visible; animation-name: fadeInRight;">
                            <h2>Anil Agarwal</h2>
                            <p><i>Chairman, Vedanta Resources Limited, and Chairman, Anil Agarwal Foundation.</i>
                            </p>

                            <h3>Message</h3>
                            <p>Education is the key to unlock the limitless potential within every individual. It
                                should not be a privilege reserved for a select few, but a right accessible to all.
                                At Vedanta Foundation, we are committed to making quality education a reality for
                                every aspiring mind, empowering them to dream, innovate, and build a better future
                                for themselves and the Nation.</p>
                            <div class="mt-30 two-column">
                                <div class="aling-items-center row">
                                    <div class="col-lg-12 col-md-12 col-xl-6 image-column">
                                        <div class="footer-social mt-10"><a href="#"><i
                                                    class="fab fa-facebook-f"></i></a> <a href="#"><i
                                                    class="fab fa-instagram"></i></a> <a href="#"><i
                                                    class="fab fa-twitter"></i></a></div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-xl-6 text-column text-right">
                                        <div class="slider-btn"><a href="#" class="btn smoth-scroll ss-btn"><i
                                                    class="fal fa-long-arrow-right"></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-100">

                    <div class="text-column col-lg-7 col-md-12 col-sm-12">
                        <div class="s-about-content pl-30 wow fadeInRight" data-animation="fadeInRight" data-delay=".2s"
                            style="visibility: visible; animation-name: fadeInRight;">
                            <h2>Suman Didwania</h2>
                            <p><i>Trustee, Vedanta P.G Girls' College, Reengus</i></p>

                            <h3>Message</h3>
                            <p>In the heart of Reengus, Vedanta P.G. Girls' College stands as a beacon of dedication and
                                commitment to the pursuit of exceptional education. Since the establishment in 2019,
                                we have been driven by the core principles of egalitarianism, aiming to break down
                                financial barriers and provide universal access to knowledge.
                                <br />
                                <br />
                                The mission of the college transcends the boundaries of traditional education.
                                Vedanta College strives not only to impart academic excellence but also to foster
                                holistic individual development. We believe in nurturing leaders, cultivating the
                                spirit of entrepreneurship for self-empowerment, and instilling a strong sense of
                                discipline in our students.
                                <br />
                                <br />
                                The collective efforts are directed towards shaping the future of young India,and
                                aspire them to empower them with the skills, competence, and unwavering confidence
                                needed to excel in every facet of life. As we move forward, let us continue to
                                uphold these ideals and work together to create a brighter and more promising
                                tomorrow.
                                <br />
                                <br />
                                Thank you for being a part of the Vedanta College family. Together, we will continue
                                to inspire and transform lives.
                            </p>
                            <div class="mt-30 two-column">
                                <div class="aling-items-center row">
                                    <div class="col-lg-12 col-md-12 col-xl-6 image-column">
                                        <div class="footer-social mt-10"><a href="#"><i
                                                    class="fab fa-facebook-f"></i></a> <a href="#"><i
                                                    class="fab fa-instagram"></i></a> <a href="#"><i
                                                    class="fab fa-twitter"></i></a></div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-xl-6 text-column text-right">
                                        <div class="slider-btn"><a href="#" class="btn smoth-scroll ss-btn"><i
                                                    class="fal fa-long-arrow-right"></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <div class="team-img-box"> <img width="460" height="500"
                                src="/vf/images/general/leadership/2.png"
                                class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt=""
                                decoding="async" fetchpriority="high"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/principalmessage" class="default-btn btn">Principal Message<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "LeadershipMessageView"
};
</script>
