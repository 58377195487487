<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>AQAR</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>NAAC</li>
                    <li>AQAR</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!-- Start AQAR Area -->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div v-for="(item, index) in data" :key="index" class="col-lg-6">
                    <div class="alumni-left-content" v-if="index % 2">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a :href="item.pdf_path" target="_blank"><i
                                            class="flaticon-pdf-file"></i>{{ item.title }}<span
                                            style="float: right;"><strong>Download</strong></span></a>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div v-else class="alumni-right-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a :href="item.pdf_path" target="_blank"><i
                                            class="flaticon-pdf-file"></i>{{ item.title }}<span
                                            style="float: right;"><strong>Download</strong></span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- End AQAR Area -->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/institutionaldistinctiveness" class="default-btn btn">Inst. Distinctiveness<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "AqarView",
    data() {
        return {
            data: null,
            right_info: '',
            left_info: ''
        };
    },
    methods: {
        async fetchData() {

            const responseValue = await fetch("json/aqar.json");
            this.data = await responseValue.json();
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>
