<template>

    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Newsletter</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Publication</li>
                    <li>Newsletter</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!-- Start newsletter area -->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">

            <div class="section-title">
                <h2>Newsletters</h2>
            </div>

            <div class="row">
                <div v-for="(item, index) in data" :key="index" class="col-lg-6">
                    <div class="alumni-left-content" v-if="index % 2">
                        <a :href="item.pdf_path" target="_blank">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img"><img :src="item.thumbnail_path" alt="Image"
                                                style="width:80%;">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>{{ item.name }}</h3>
                                            <div class="location">
                                                <p><i class="flaticon-user"></i><strong></strong>{{ item.author }}
                                                </p>
                                            </div>
                                            <div class="location">
                                                <p><i class="flaticon-time"></i><strong></strong>{{ item.year }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>

                    </div>

                    <div v-else class="alumni-right-content">
                        <a :href="item.pdf_path" target="_blank">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img"><img :src="item.thumbnail_path" alt="Image"
                                                style="width:80%;">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>{{ item.name }}</h3>
                                            <div class="location">
                                                <p><i class="flaticon-user"></i><strong></strong>{{ item.author }}
                                                </p>
                                            </div>
                                            <div class="location">
                                                <p><i class="flaticon-time"></i><strong></strong>{{ item.year }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End newsletter area -->


</template>

<script>
export default {
    name: "NewsletterView",
    data() {
        return {
            data: null,
            right_staff_info: '',
            left_staff_info: ''
        };
    },
    methods: {
        async fetchData() {

            const responseValue = await fetch("json/newsletter.json");
            this.data = await responseValue.json();
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>
