<template>
  <!--Start Page Banner-->
  <div class="page-banner-area bg-1">
    <div class="container">
      <div class="page-banner-content">
        <h1>About College</h1>
        <ul>
          <li><a href="/">Home</a></li>
          <li>About</li>
          <li>About College</li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Page Banner-->

  <!-- About colelge -->
  <div class="campus-information-area pb-70 pt-70">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-6 aos-init aos-animate"
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1300"
          data-aos-once="true"
        >
          <div class="campus-content pr-20">
            <div class="campus-title">
              <h2>Welcome to Vedanta P.G. Girls College</h2>
              <p>
                Nestled in the heart of Reengus, Rajasthan, Vedanta P.G Girls
                College stands as a beacon of education, empowerment, and
                excellence. With the prestigious N.A.A.C accreditation, our
                college has been a guiding light for aspiring young women since
                its establishment in 1992. Serving as a vibrant hub of knowledge
                and growth, we have been instrumental in shaping the futures of
                over 37,000 students from Reengus and the surrounding rural
                communities.
                <br />
                <br />
                Dedicated to fostering academic brilliance and holistic
                development, Vedanta P.G Girls College offers a diverse range of
                Post-Graduation and Graduation courses in Arts, Science, and
                Commerce. We believe in making education accessible and
                affordable to all.
                <br />
                <br />
                The subsidized fee structure ensures that financial constraints
                do not hinder the pursuit of knowledge. We extend financial aid
                to meritorious student and to those facing economic challenges.
              </p>
            </div>
            <div class="list">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <router-link to="/programoffered"
                          >UG/PG Programs</router-link
                        >
                      </p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <router-link href="/campus">Modern Labs</router-link>
                      </p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <router-link href="/campus"
                          >Hostel Facility</router-link
                        >
                      </p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <router-link href="/campus"
                          >Sports and Fitness</router-link
                        >
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6">
                  <ul>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <router-link href="/placement">Placements</router-link>
                      </p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <a href="../admission/tours_excursion.html"
                          >Tours and Excursions</a
                        >
                      </p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <router-link to="/teachingstaff"
                          >Qualified Staff</router-link>
                      </p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>
                        <router-link to="/vocationalcenter"
                          >Certification Courses</router-link>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <router-link to="/applynow" class="default-btn btn"
              >Start your application<i class="flaticon-next"></i
            ></router-link>
          </div>
        </div>
        <div
          class="col-lg-6 aos-init aos-animate"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1300"
          data-aos-once="true"
        >
          <div class="campus-image pl-20">
            <img src="/vf/images/about/about_S2.png" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About college end -->

  <!--Start Vision-Mission Area-->
  <div class="academic-area pt-10 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Vision-Mission</h2>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-lg-6 col-md-6"
          data-aos-duration="1200"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="single-academics-card">
            <div class="academic-top-content">
              <i class="flaticon-college-graduation"></i>
              <a href="#">
                <h3>Vision</h3>
              </a>
            </div>
            <p>
              The College is committed towards elevating women's higher
              education and research, offering enriching educational experiences
              in various fields while embracing both human and spiritual
              aspects. Our focus is on creating meaningful institute, not just
              physical buildings.
            </p>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6"
          data-aos-duration="1200"
          data-aos-delay="400"
          data-aos-once="true"
        >
          <div class="single-academics-card">
            <div class="academic-top-content">
              <i class="flaticon-graduation"></i>
              <a href="#">
                <h3>Mission</h3>
              </a>
            </div>
            <p>
              The mission of the college is to provide a wide array of academic
              and practical courses accessible to a diverse student body. We aim
              to deliver high-quality, professional, and impactful education to
              empower rural women for comprehensive development and enhanced
              career prospects.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Vision-Mission Area-->

  <!-- Admission -->
  <div class="admisssion-area ptb-100 admission-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="admission-left-content">
            <h2>Your Learning Starts Today!</h2>
            <p>
              We believe that a well-rounded education is essential, and offer
              our students the opportunity to get involved in a variety of
              activities that interest them.
            </p>
            <router-link to="/applynow" class="default-btn btn"
              >More on admission<i class="flaticon-next"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="admission-right-content">
            <ul>
              <li>
                <p>Watch Video</p>
              </li>
              <li>
                <div class="icon">
                  <a class="popup-youtube play-btn" href="#"
                    ><i class="ri-play-fill"></i
                  ></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- College FAQs -->
  <div class="faq-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="faq-image pr-20">
            <img src="/vf/images/about/about_S3.png" alt="Image" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="faq-left-content pl-20">
            <div class="faq-title">
              <h2>Why Choose Vedanta P.G. Girls College?</h2>
              <p>
                At Vedanta P.G. Girls College, we offer a distinctive
                educational experience that goes beyond traditional learning.
                Here are several compelling reasons why Vedanta P.G. Girls
                College is your ideal choice:
              </p>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. Quality education at an affordable cost
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Vedanta P.G Girls’ College provides quality education to
                    girls at an affordable cost with the support of qualified
                    faculty.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. State of the Art Infrastructure
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    The infrastructure facilities are geared towards enhancing
                    the overall educational experience, providing students with
                    the tools they need to excel in their chosen fields.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. Digital Pedagogy and Smart Classrooms
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    In today's tech-driven world, it's essential for students to
                    be tech-savvy and comfortable with digital learning. Vedanta
                    P.G College embraces digital pedagogy and offers smart
                    classrooms equipped with modern technology. This ensures
                    that students have access to a contemporary learning
                    environment that prepares them for the digital age.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    4. Career Counseling
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Making informed decisions about one's career path is
                    crucial. Vedanta P.G College provides career counseling
                    services to help students identify their strengths,
                    interests, and career goals. This guidance ensures that
                    students are on the right track and are making choices
                    aligned with their aspirations.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    5. Modern Hostel Facility
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    For students coming from different regions or those who
                    prefer a residential option, Vedanta P.G College provides
                    hostel facilities. These hostels offer a safe and conducive
                    environment for students to focus on their studies while
                    enjoying a sense of community with fellow students.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Infra -->
  <div class="health-care-area ptb-100 bg-f4f6f9">
    <div class="container">
      <div class="section-title">
        <h2>Forging Lasting Bonds on Campus</h2>
        <p>
          Cultivating Friendships, Crafting Memories, and Nurturing Lifelong
          Connections in the Heart of Learning
        </p>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-6">
          <div class="single-health-care-card style1">
            <div class="img">
              <router-link to="/campus"
                ><img src="/vf/images/about/about_S4_1.png" alt="Image"
              /></router-link>
            </div>
            <div class="health-care-content">
              <router-link to="/campus">
                <h3>Campus Tour</h3>
              </router-link>
              <p>
                From cutting-edge classrooms to cozy study nooks, discover the
                heartbeat of our campus. Unearth opportunities, forge
                connections, and embrace the spirit of higher learning.
                #CampusAdventure #FutureLeaders
              </p>
              <router-link to="/campus" class="read-more-btn"
                >Know More <i class="flaticon-next"></i
              ></router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="single-health-care-card style2">
            <div class="img">
              <router-link to="/alumni"
                ><img src="/vf/images/about/about_S4_2.png" alt="Image"
              /></router-link>
            </div>
            <div class="health-care-content">
              <router-link to="/alumni">
                <h3>Alumni Connect</h3>
              </router-link>
              <p>
                Celebrating the alumni family that shapes our story. From shared
                memories to future triumphs, we stand strong together. Once a
                part of us, always a part of us. Here's to lifelong connections
                and the spirit of success. #AlumniPride #ForeverConnected"
              </p>
              <router-link to="/alumni" class="read-more-btn"
                >Know More <i class="flaticon-next"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Start Bottom Connect Area -->
  <div class="alumni-interview-area pb-100 pt-100">
    <div class="container">
      <div class="interview-content ptb-100">
        <div class="interview">
          <router-link to="/campus" class="default-btn btn"
            >Campus<i class="flaticon-next"></i
          ></router-link>
          <router-link to="/applynow" class="default-btn btn"
            >Apply Now<i class="flaticon-next"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
  <!--End Bottom Connect Area -->
</template>

<script>
export default {
  name: "AboutCollegeView",
};
</script>
