<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>College Brochure</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Admission</li>
                    <li>College Brochure</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start brochure Area-->
    <div class="admisssion-area ptb-100">
        <div class="container">
            <div class="admission-content">
                <div class="section-title">
                    <h2>Vedanta P.G. Girls College</h2>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit tellus luctus nec ullamcorper mattis </p> -->
                </div>
                <div class="admission-image">
                    <img src="vf/images/general/college_brochure.png" alt="Image">
                    <div class="icon">
                        <a class="popup-youtube play-btn" href="#"><i class="ri-play-fill"></i></a>
                    </div>
                </div>
                <div class="query text-center">
                    <a href="vf/documents/admission/college_brochure/brochure_2023_2024.pdf"
                        class="default-btn btn">Download<i class="flaticon-next"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!--End brochure Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/admissioncommittee" class="default-btn btn">Adm. Committee<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "CollegeBrochureView"
};
</script>
