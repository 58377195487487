<!-- CarousalCompTypeThre : Image -->
<template>
  <Carousel :items-to-show="4" :wrap-around="true" :transition="2000" :autoplay="1000">
    <Slide v-for="(info, index) in contentInfo" :key="index">
      <div class="single-events-card style2" style="width: 200px;border: solid 1px white;">
        <div class="single-brand">
          <img :src="info.image" alt="img">
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
// import carousel from 'vue-owl-carousel'
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "CarousalCompTypeThree",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {

    };
  },
  props: {
    contentInfo: {
      type: Array,
      required: true
    }
  }
};
</script>
