import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomePage.vue";

// Top Header Nav
import AdmissionView from "@/views/AdmissionView.vue";
import GalleryView from "@/views/GalleryView.vue";
import PlacementView from "@/views/PlacementView.vue";
import ContactView from "@/views/ContactView.vue";

// About Nav
import AboutCollegeView from "@/views/about/AboutCollegeView.vue";
import CampusView from "@/views/about/CampusView.vue";
import LeadershipMessageView from "@/views/about/LeadershipMessageView.vue";
import PrincipalMessageView from "@/views/about/PrincipalMessageView.vue";
import AffiliationView from "@/views/about/AffiliationView.vue";
import OrganogramView from "@/views/about/OrganogramView.vue";
import GoverningBodyView from "@/views/about/GoverningBodyView.vue";

// Academics Nav
import DepartmentView from "@/views/academics/DepartmentView.vue";
import SyllabusView from "@/views/academics/SyllabusView.vue";
import SubjectsView from "@/views/academics/SubjectsView.vue";
import VocationCenterView from "@/views/academics/VocationCenterView.vue";
import AcademicCalendarView from "@/views/academics/AcademicCalendarView.vue";
import TeachingStaffView from "@/views/academics/TeachingStaffView.vue";
import NonTeachingStaffView from "@/views/academics/NonTeachingStaffView.vue";
import CollaborationView from "@/views/academics/CollaborationView.vue";
import EResourcesView from "@/views/academics/EResourcesView.vue";

// Admission Nav
import ApplyNowView from "@/views/admission/ApplyNowView.vue";
import ProgramOfferedView from "@/views/admission/ProgramOfferedView.vue";
import EligiblityCriteriaView from "@/views/admission/EligiblityCriteriaView.vue";
import FeeDetailsView from "@/views/admission/FeeDetailsView.vue";
import ScholarshipView from "@/views/admission/ScholarshipView.vue";
import CollegeBrochureView from "@/views/admission/CollegeBrochureView.vue";
import AdmissionCommitteeView from "@/views/admission/AdmissionCommitteeView.vue";

// Student Corner Nav
import AchievementsView from "@/views/studentcorner/AchievementsView.vue";
import AlumniView from "@/views/studentcorner/AlumniView.vue";
import CellsView from "@/views/studentcorner/CellsView.vue";
import PoliciesView from "@/views/studentcorner/PoliciesView.vue";

// Publication Nav
import NewsletterView from "@/views/publication/NewsletterView.vue";
import MediaView from "@/views/publication/MediaView.vue";
import EditorialBoardView from "@/views/publication/EditorialBoardView.vue";

// IQAC Nav
import IqacAboutView from "@/views/iqac/IqacAboutView.vue";
import IqacCompositionView from "@/views/iqac/IqacCompositionView.vue";
import IqacMeetingView from "@/views/iqac/IqacMeetingView.vue";
import IqacNoticesView from "@/views/iqac/IqacNoticesView.vue";
import AqarView from "@/views/iqac/AqarView.vue";
import ClubCommitteeView from "@/views/iqac/ClubCommitteeView.vue";

// NAAC Nav
import NaacCertificateView from "@/views/naac/NaacCertificateView.vue";
import AisheView from "@/views/naac/AisheView.vue";
import IDView from "@/views/naac/IDView.vue";
import BestPractisesView from "@/views/naac/BestPractisesView.vue";
import CriteriaView from "@/views/naac/CriteriaView.vue";
import PeerReviewView from "@/views/naac/PeerReviewView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admission",
    name: "Admission",
    component: AdmissionView,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: GalleryView,
  },
  {
    path: "/placement",
    name: "Placement",
    component: PlacementView,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  // about nav
  {
    path: "/aboutcollege",
    name: "AboutCollege",
    component: AboutCollegeView,
  },
  {
    path: "/campus",
    name: "Campus",
    component: CampusView,
  },
  {
    path: "/leadershipmessage",
    name: "LeadershipMessage",
    component: LeadershipMessageView,
  },
  {
    path: "/principalmessage",
    name: "PrincipalMessage",
    component: PrincipalMessageView,
  },
  {
    path: "/affiliation",
    name: "Affiliation",
    component: AffiliationView,
  },
  {
    path: "/organogram",
    name: "Organogram",
    component: OrganogramView,
  },
  {
    path: "/governingbody",
    name: "GoverningBody",
    component: GoverningBodyView,
  },

  // academics Nav
  {
    path: "/department",
    name: "DepartmentView",
    component: DepartmentView,
  },
  {
    path: "/syllabus",
    name: "SyllabusView",
    component: SyllabusView,
  },
  {
    path: "/subjects",
    name: "SubjectsView",
    component: SubjectsView,
  },
  {
    path: "/vocationalcenter",
    name: "VocationCenterView",
    component: VocationCenterView,
  },
  {
    path: "/academiccalender",
    name: "AcademicCalendarView",
    component: AcademicCalendarView,
  },
  {
    path: "/teachingstaff",
    name: "TeachingStaffView",
    component: TeachingStaffView,
  },
  {
    path: "/nonteachingstaff",
    name: "NonTeachingStaffView",
    component: NonTeachingStaffView,
  },
  {
    path: "/collaboration",
    name: "CollaborationView",
    component: CollaborationView,
  },
  {
    path: "/eresources",
    name: "EResourcesView",
    component: EResourcesView,
  },

  // Admission Nav
  {
    path: "/applynow",
    name: "ApplyNowView",
    component: ApplyNowView,
  },
  {
    path: "/programoffered",
    name: "ProgramOfferedView",
    component: ProgramOfferedView,
  },
  {
    path: "/eligiblitycriteria",
    name: "EligiblityCriteriaView",
    component: EligiblityCriteriaView,
  },
  {
    path: "/feedetails",
    name: "FeeDetailsView",
    component: FeeDetailsView,
  },
  {
    path: "/scholarship",
    name: "ScholarshipView",
    component: ScholarshipView,
  },
  {
    path: "/collegebrochure",
    name: "CollegeBrochureView",
    component: CollegeBrochureView,
  },
  {
    path: "/admissioncommittee",
    name: "AdmissionCommitteeView",
    component: AdmissionCommitteeView,
  },

  // Student Corner Nav
  {
    path: "/achievements",
    name: "AchievementsView",
    component: AchievementsView,
  },
  {
    path: "/alumni",
    name: "AlumniView",
    component: AlumniView,
  },
  {
    path: "/cells",
    name: "CellsView",
    component: CellsView,
  },
  {
    path: "/policies",
    name: "PoliciesView",
    component: PoliciesView,
  },

  // Publication Nav
  {
    path: "/newsletter",
    name: "NewsletterView",
    component: NewsletterView,
  },
  {
    path: "/media",
    name: "MediaView",
    component: MediaView,
  },
  {
    path: "/editorialboard",
    name: "EditorialBoardView",
    component: EditorialBoardView,
  },

  // IQAC
  {
    path: "/iqacabout",
    name: "IqacAboutView",
    component: IqacAboutView,
  },
  {
    path: "/iqaccomposition",
    name: "IqacCompositionView",
    component: IqacCompositionView,
  },
  {
    path: "/iqacmeeting",
    name: "IqacMeetingView",
    component: IqacMeetingView,
  },
  {
    path: "/iqacnotices",
    name: "IqacNoticesView",
    component: IqacNoticesView,
  },
  {
    path: "/aqar",
    name: "AqarView",
    component: AqarView,
  },
  {
    path: "/clubcommittee",
    name: "ClubCommittee",
    component: ClubCommitteeView,
  },

  // NAAC
  {
    path: "/naaccertificate",
    name: "NaacCertificate",
    component: NaacCertificateView,
  },
  {
    path: "/aishe",
    name: "AisheView",
    component: AisheView,
  },
  {
    path: "/institutionaldistinctiveness",
    name: "IDView",
    component: IDView,
  },
  {
    path: "/bestpractises",
    name: "BestPractisesView",
    component: BestPractisesView,
  },
  {
    path: "/criteria",
    name: "CriteriaView",
    component: CriteriaView,
  },
  {
    path: "/peerreview",
    name: "PeerReviewView",
    component: PeerReviewView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
});

export default router;
