<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1" >
            <div class="container">
                <div class="page-banner-content">
                    <h1>eResources</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Academics</li>
                        <li>eResources</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--End Page Banner-->


        <!--Start resource Area-->
        <div class="alumni-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">

                        <div class="alumni-left-content">
                            <div class="tranding">
                                <div class="tranding-box">
                                    <div class="tranding-content">
                                        <a href="https://www.inflibnet.ac.in/" target="_blank"><img
                                                src="vf/images/eresources/1.png" alt="Image"></a>
                                        <h4><a href="https://www.inflibnet.ac.in/" target="_blank">Inflibnet</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="tranding">
                                <div class="tranding-box">
                                    <div class="tranding-content">
                                        <a href="https://swayam.gov.in/nc_details/NPTEL" target="_blank"><img
                                                src="vf/images/eresources/3.png" alt="Image"></a>
                                        <h4><a href="https://swayam.gov.in/nc_details/NPTEL" target="_blank">Swayam</a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="alumni-right-content">
                            <div class="tranding">
                                <div class="tranding-box">
                                    <div class="tranding-content">
                                        <a href="https://www.uniraj.ac.in/" target="_blank"><img
                                                src="vf/images/eresources/4.png" alt="Image"></a>
                                        <h4><a href="https://www.caluniv.ac.in/" target="_blank">University of
                                                Rajasthan</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="tranding">
                                <div class="tranding-box">
                                    <div class="tranding-content">
                                        <a href="https://shekhauni.ac.in/" target="_blank"><img
                                                src="vf/images/eresources/2.png" alt="Image"></a>
                                        <h4><a href="https://shekhauni.ac.in/" target="_blank">Shekhawati University</a></h4>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End resource Area-->

        <!-- Start Bottom Connect Area -->
        <div class="alumni-interview-area pb-100">
            <div class="container">
                <div class="interview-content ptb-100">
                    <div class="interview">
                        <router-link to="/programoffered" class="default-btn btn">Programs Offered<i class="flaticon-next"></i></router-link>
                        <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name : "EResourcesView"
};
</script>
