<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>News Media</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Publication</li>
                    <li>News Media</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!-- Start Media Area -->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Campus Chronicle</h2>
                <p>Capturing the Essence of College Life</p>
            </div>
            <div style="text-align: center;">
                <div class="thumbnails" v-for="(publication, index) in this.publications" :key="index">
                    <img style="width:250px;height:250px;border:solid 1px lightgray;padding:5px;"
                        :src="publication.thumbnail_path" @click="openLightbox(index)" />
                </div>
            </div>

        </div>
    </div>

    <!-- End Media Area -->
</template>

<script>

export default {
    name: "MediaView",
    data() {
        return {
            publications: null,
        };
    },
    methods: {
        async fetchData() {

            const responseValue = await fetch("json/newsmedia.json");
            this.publications = await responseValue.json();
            // this.lightboxSources = this.publications.map((publication) => publication.image_path);

        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>
<style>
.thumbnails {
    display: inline;
    flex-wrap: wrap;
    
}

.thumbnails img {
    width: 100px;
    /* Adjust as needed */
    height: auto;
    /* Maintain aspect ratio */
    margin: 5px;
    /* Adjust spacing between thumbnails */
    /* cursor: pointer; */
}
</style>