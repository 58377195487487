<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Campus</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>About</li>
                    <li>Campus</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Campus Life Area-->
    <div class="campus-life-area pt-100 pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-campus-card">
                        <div class="img">
                            <a href="#"><img src="/vf/images/campus/C1.png" alt="Image"></a>
                        </div>
                        <div class="campus-content">
                            <h3><span>Smart Classrooms</span></h3>
                            <a href="#">
                                <p>The classrooms are thoughtfully crafted to facilitate a seamless blend of modern
                                    pedagogical practices and student comfort</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-campus-card">
                        <div class="img">
                            <a href="#"><img src="/vf/images/campus/C2.png" alt="Image"></a>
                        </div>
                        <div class="campus-content">
                            <h3><span>Library</span></h3>
                            <a href="#">
                                <p>The state-of-the-art infrastructure, offering an extensive array of reading
                                    materials, articles, and diverse e-resources</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-campus-card">
                        <div class="img">
                            <a href="#"><img src="/vf/images/campus/C3.png" alt="Image"></a>
                        </div>
                        <div class="campus-content">
                            <h3><span>IT Laboratory</span></h3>
                            <a href="#">
                                <p>The IT Laboratory is highly sought-after, offering foundational computer courses
                                    alongside advanced programming classes</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-campus-card">
                        <div class="img">
                            <a href="#"><img src="/vf/images/campus/C4.png" alt="Image"></a>
                        </div>
                        <div class="campus-content">
                            <h3><span>Science Laboratories</span></h3>
                            <a href="#">
                                <p>All Labs are equipped with robust
                                    infrastructure enhances the practical learning and in-depth subject
                                    understanding</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-campus-card">
                        <div class="img">
                            <a href="#"><img src="/vf/images/campus/C5.png" alt="Image"></a>
                        </div>
                        <div class="campus-content">
                            <h3><span>Hostel Facilities</span></h3>
                            <a href="#">
                                <p>The hostel provide a home away from home, with mordern, well-furnished rooms,
                                    round-the-
                                    clock security and hygienic meals.</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-campus-card">
                        <div class="img">
                            <a href="#"><img src="/vf/images/campus/C6.png" alt="Image"></a>
                        </div>
                        <div class="campus-content">
                            <h3><span>Sports Facilities</span></h3>
                            <a href="#">
                                <p>The college provides indoor and outdoor facilities that exemplify the transformative
                                    power of athletics in shaping lives and shaping futures.</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Campus Life Area-->

    <!--Start Campus Safety-->
    <div class="campus-safety-area pt-100 pb-70 bg-f4f6f9">
        <div class="container">
            <div class="section-title">
                <h2>Campus Matrices</h2>
                <p>Navigating Excellence Through Numbers</p>
            </div>

            <div class="counter-area" style="text-align: center;">
                <div class="row">
                    <div class="col">
                        <div class="counter-card">
                            <h1>
                                <span class="odometer" data-count="2500">2500</span>
                                <!-- <vue-odometer  :value="2500" animation="smooth"></vue-odometer> -->
                                <span class="target">+</span>
                            </h1>
                            <p>Students per year</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="counter-card">
                            <h1>
                                <span class="odometer" data-count="30">30</span>
                                <span class="target">+</span>
                            </h1>
                            <p>Affiliated Programs</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="counter-card">
                            <h1>
                                <span class="odometer" data-count="62">62</span>
                                <span class="target">+</span>
                            </h1>
                            <p>Qualified Staff</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Campus Safety-->


    <!--Start Student Life Area-->
    <div class="student-life-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Student Life @ Vedanta P.G. Girls College</h2>
                <p>A vibrant tapestry of experiences, blending academia, personal growth, and a sense of community.
                </p>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="student-life-card">
                        <img src="/vf/images/home/SL1.png" alt="Image">
                        <div class="tags">
                            <p>Counselling</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="student-life-card">
                        <img src="/vf/images/campus/SL2.png" alt="Image">
                        <div class="tags">
                            <p>Integrated Teaching</p>
                        </div>
                    </div>
                    <div class="student-life-card">
                        <img src="/vf/images/campus/SL1.png" alt="Image">
                        <div class="tags">
                            <p>Self Defense</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Student Life Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/leadershipmessage" class="default-btn btn">Leadership Message<i
                            class="flaticon-next"></i></router-link>
                    <router-link to="/applynow" class="default-btn btn">Apply Now<i
                            class="flaticon-next"></i></router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->


</template>

<script>

export default {
    name: "CampusView",
};
</script>
