<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Peer Review</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>NAAC</li>
                    <li>Peer Review</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!-- Start AISHE area -->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">

                    <div class="alumni-left-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="vf/documents/naac/others/peer_team_report_28-30_January_2016.pdf" target="_blank"><i
                                            class="flaticon-pdf-file"></i>Peer Review : 28-30 January 2016<span
                                            style="float: right;"><strong>Download</strong></span></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <!-- <div class="alumni-left-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="vf/documents/naac/others//02_AISHE_Certificate_2022_23.pdf"
                                        target="_blank"><i class="flaticon-pdf-file"></i>AISHE : 2022-2023<span
                                            style="float: right;"><strong>Download</strong></span></a>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
    <!-- End Aishe area -->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link href="/naaccertificate" class="default-btn btn">NAAC Cert.<i class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->

</template>

<script>
export default {
    name: "AisheView"
};
</script>
