<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Fee Details</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Admission</li>
                    <li>Fee Details</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start fee detail Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">

                    <div class="alumni-left-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="vf/documents/admission/fees/PG_College_fee_structure 23-24.pdf"
                                        target="_blank"><i class="flaticon-pdf-file"></i>Fees Structure 2023 - 2024<span
                                            style="float: right;"><strong>Download</strong></span></a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                </div>
            </div>
        </div>
    </div>
    <!--end fee detail Area-->


</template>

<script>
export default {
    name: "FeeDetailsView"
};
</script>
