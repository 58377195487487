<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Policies</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Student Corner</li>
                    <li>Policies</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Polices Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="alumni-left-content">
                        <div class="single-alumni-events-card">
                            <div class="row align-items-center">
                                <div class="col-lg-5 col-md-5">
                                    <div class="alumni-img">
                                        <img src="vf/images/policies/p2.png" alt="Image">
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <div class="alumni-content">
                                        <h3>Anti Ragging Policy</h3>
                                        <div class="location">
                                            <p>promote and maintain conducive and unprejudiced educational
                                                environment for unfettered learning</p>
                                        </div>
                                        <a href="../studentcorner/anti_ragging.html">Click Here</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="single-alumni-events-card">
                            <div class="row align-items-center">
                                <div class="col-lg-5 col-md-5">
                                    <div class="alumni-img">
                                        <img src="vf/images/policies/p1.png" alt="Image">
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <div class="alumni-content">
                                        <h3>Code of Conduct</h3>
                                        <div class="location">
                                            <p>Rules student must follow on the premises</p>
                                        </div>
                                        <a href="../studentcorner/code_of_conduct.html">Click Here</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="alumni-right-content">
                        <div class="single-alumni-events-card">
                            <div class="row align-items-center">
                                <div class="col-lg-5 col-md-5">
                                    <div class="alumni-img">
                                        <img src="vf/images/policies/p3.png" alt="Image">
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <div class="alumni-content">
                                        <h3>Best Practices</h3>
                                        <div class="location">
                                            <p>Organises a number of activities in all its academic sessions that aims
                                                to better the quality of life and living</p>
                                        </div>
                                        <a href="../naac/best_practises.html">Click Here</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="single-alumni-events-card">
                            <div class="row align-items-center">
                                <div class="col-lg-5 col-md-5">
                                    <div class="alumni-img">
                                        <img src="vf/images/policies/p4.png" alt="Image">
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <div class="alumni-content">
                                        <h3>Admission Policy</h3>
                                        <div class="location">
                                            <p>Rules student must follow at time of admission</p>
                                        </div>
                                        <a href="../naac/admission_policies/2022_2023/Admissionpolicy_2022-23.pdf"
                                            target="_blank">Click Here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
    <!--End Polices Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <a href="https://rdc.eshala.co.in/events" class="default-btn btn">Events<i
                            class="flaticon-next"></i></a>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->



</template>

<script>
export default {
    name: "PoliciesView"
};
</script>
