<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Alumni</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Student Corner</li>
                    <li>Alumni</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!-- Start Alumni Area -->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Join Our College Alumni Community </h2>
                <p>Vedanta P.G Girls' College Reengus has long been a beacon of education, enlightenment, and
                    empowerment in
                    the heart of the City of Joy. As alumni, you carry with you not only the knowledge and skills
                    imparted by the alma mater but also the values, friendships, and experiences that have shaped
                    you into the individuals you are today.</p>
                <p>This alumni page is more than just a digital space; it's a testament to the enduring bonds that
                    unite us as Vedantians. Whether you graduated recently or decades ago, we invite you to
                    reconnect, reminisce, and stay connected with your fellow alumni. </p>
            </div>
            <div class="row">
                <div class="col-lg-6">

                    <div class="alumni-left-content-2">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="vf/documents/alumni/Registration_Certificate_for_Alumni.pdf" target="_blank"><i
                                            class="flaticon-search"></i>
                                        Alumni Certificate <span style="float: right;"><strong>Download</strong></span></a>

                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="alumni-left-content-2">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="vf/documents/alumni/ALUMNI_ENGAGEMENT_2015_TO_2023.pdf" target="_blank"><i
                                            class="flaticon-search"></i>
                                        Alumni Engagement 2015-2023 <span style="float: right;"><strong>Download</strong></span></a>

                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div class="col-lg-6">

                    <div class="alumni-left-content-2">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="https://forms.gle/yZnLn1svJyaVSX2c9" target="_blank"><i
                                            class="flaticon-search"></i>
                                        Alumni Connect <span style="float: right;"><strong>Register
                                                Here</strong></span></a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <h2 style="text-align: center;" class="pt-30">Prominent Alumni</h2>

            <!--Start Teaching Area-->
            <div class="alumni-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div v-for="(item, index) in data" :key="index" class="col-lg-6">
                            <div class="alumni-left-content" v-if="index % 2">
                                <div class="single-alumni-events-card">
                                    <div class="row align-items-center">
                                        <div class="col-lg-5 col-md-5">
                                            <div class="alumni-img"><img :src="item.photo" alt="Image"
                                                    style="width:80%;">
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-7">
                                            <div class="alumni-content">
                                                <h3>{{ item.name }}</h3>
                                                <div class="location">
                                                    <p><i
                                                            class="ri-medal-2-fill"></i><strong></strong>{{ item.designation }}
                                                    </p>
                                                </div>
                                                <div class="location">
                                                    <p><i
                                                            class="ri-graduation-cap-line"></i><strong></strong>{{ item.qualification }}
                                                    </p>
                                                </div>
                                                <div class="time">
                                                    <p><i
                                                            class="flaticon-time"></i><strong></strong>{{ item.experience }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div v-else class="alumni-right-content">
                                <div class="single-alumni-events-card">
                                    <div class="row align-items-center">
                                        <div class="col-lg-5 col-md-5">
                                            <div class="alumni-img"><img :src="item.photo" alt="Image"
                                                    style="width:80%;">
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-7">
                                            <div class="alumni-content">
                                                <h3>{{ item.name }}</h3>
                                                <div class="location">
                                                    <p><i
                                                            class="ri-medal-2-fill"></i><strong></strong>{{ item.designation }}
                                                    </p>
                                                </div>
                                                <div class="location">
                                                    <p><i
                                                            class="ri-graduation-cap-line"></i><strong></strong>{{ item.qualification }}
                                                    </p>
                                                </div>
                                                <div class="time">
                                                    <p><i
                                                            class="flaticon-time"></i><strong></strong>{{ item.experience }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--End Teaching Area-->
        </div>
    </div>
    <!-- End Alumni Area -->


</template>

<script>
export default {
    name: "AlumniView",
    data() {
        return {
            data: null,
            right_staff_info: '',
            left_staff_info: ''
        };
    },
    methods: {
        async fetchData() {

            const responseValue = await fetch("json/alumni.json");
            this.data = await responseValue.json();
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>
