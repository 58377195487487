<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1" >
            <div class="container">
                <div class="page-banner-content">
                    <h1>Governing Body</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>About</li>
                        <li>Governing Body</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--End Page Banner-->

        <!--Start Alumni Area-->
        <div class="alumni-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">

                        <div class="alumni-left-content">
                            <div class="related-download">
                                <ul>
                                    <li>
                                        <a href="/vf/documents/about/governing_body.pdf"
                                            target="_blank"><i class="flaticon-pdf-file"></i>
                                            Governing Body<span style="float: right;"><strong>Download</strong></span></a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6" style="display: none;">
                        <div class="alumni-right-content">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/images/alumni/alumni-1.jpg" alt="Image">
                                            <div class="date">
                                                <p>Apr 11,2022</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i>1:00 pm - 1:00 pm</p>
                                            </div>
                                            <h3>Fintech & Key Investment Conference</h3>
                                            <div class="location">
                                                <p><i class="ri-map-pin-line"></i>vf Grand Hall</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Alumni Area-->

        <!-- Start Bottom Connect Area -->
        <div class="alumni-interview-area pb-100">
            <div class="container">
                <div class="interview-content ptb-100">
                    <div class="interview">
                        <!-- <span>Alumni Interview</span>
                        <h2>Eliana Brooklyn</h2>
                        <p>Lorem ipsum dolor sit amet cons ctetur adip iscing elit sed do eiusmod tem incid idunt ut
                            labore et dolore magna ali q ua. Ut enim ad minim ven iam quis nostrud xerci tation mco
                            laboris nisi ut Lorem ipsum dolor </p> -->
                        <router-link to="/admissioncommittee" class="default-btn btn">Admission Committee<i class="flaticon-next"></i></router-link>
                        <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name : "GoverningBodyView"
};
</script>
