<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Subjects</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Academics</li>
                    <li>Subjects</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Alumni Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">

                    <div class="alumni-left-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="/vf/documents/academics/major_minor_subjects.pdf"
                                        target="_blank"><i class="flaticon-pdf-file"></i>Subjects
                                        Combination<span style="float: right;"><strong>Download</strong></span></a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="display: none;">
                    <div class="alumni-right-content">
                        <div class="single-alumni-events-card">
                            <div class="row align-items-center">
                                <div class="col-lg-5 col-md-5">
                                    <div class="alumni-img">
                                        <img src="/images/alumni/alumni-1.jpg" alt="Image">
                                        <div class="date">
                                            <p>Apr 11,2022</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <div class="alumni-content">
                                        <div class="time">
                                            <p><i class="flaticon-time"></i>1:00 pm - 1:00 pm</p>
                                        </div>
                                        <h3>Fintech & Key Investment Conference</h3>
                                        <div class="location">
                                            <p><i class="ri-map-pin-line"></i>vf Grand Hall</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Alumni Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/vocationalcenter" class="default-btn btn">Vocational Center<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "SubjectsView"
};
</script>
