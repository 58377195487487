<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Admission Committee</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Admission</li>
                    <li>Admission Committee</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start committee Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">

                    <div class="alumni-left-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="vf/documents/about/WORKING-COMMITTEE-SESSION-2023-24.pdf"
                                        target="_blank"><i class="flaticon-pdf-file"></i>Admission Committee
                                        2023 - 2024<span style="float: right;"><strong>Download</strong></span></a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                </div>
            </div>
        </div>
    </div>
    <!--End committee Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/achievements" class="default-btn btn">Achievements<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->

</template>

<script>
export default {
    name: "AdmissionCommitteeView"
};
</script>
