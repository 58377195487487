<template>
  <!--Start Page Banner-->
  <div class="page-banner-area bg-1">
    <div class="container">
      <div class="page-banner-content">
        <h1>Contact Us</h1>
        <ul>
          <li><a href="/">Home</a></li>
          <li>Contact Us</li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Page Banner-->

  <div class="contact-us-area pt-100 pb-70">
    <div class="container">
      <div class="row">
      <div class="col-lg-6"></div>
        <div class="col-lg-6">
          <div class="contact-and-address">
            <h2>Get in Touch: Connecting Dreams to Reality</h2>
            <div class="contact-and-address-content">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="contact-card">
                    <div class="icon">
                      <i class="ri-phone-line"></i>
                    </div>
                    <h4>Contact</h4>
                    <p><a href="tel:+911575224549 ">(+91)-01575- 224549 </a></p>
                    <p>
                      <a href="mailto:principal.ringus@gmail.com"
                        >principal.ringus@gmail.com</a
                      >
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="contact-card">
                    <div class="icon">
                      <i class="ri-map-pin-line"></i>
                    </div>
                    <h4>Address</h4>
                    <p>
                      Opp. Nagar Palika, Khatushyamji Road, Ringus, Rajasthan-
                      332404
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="social-media">
              <h3>Social Media</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tem incididunt ut labore et dolore magna aliquat enim</p> -->
              <ul>
                <li>
                  <a href="https://www.facebook.com" target="_blank"
                    ><i class="flaticon-facebook"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.twitter.com" target="_blank"
                    ><i class="flaticon-twitter"></i
                  ></a>
                </li>
                <li>
                  <a href="https://instagram.com/?lang=en" target="_blank"
                    ><i class="flaticon-instagram"></i
                  ></a>
                </li>
                <li>
                  <a href="https://linkedin.com/?lang=en" target="_blank"
                    ><i class="flaticon-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactView",
};
</script>
