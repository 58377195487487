<!-- Home.vue -->
<template>
  <div class="preloader-area" :show="displayLoader">
    <div class="preloader__image"></div>
  </div>

  <HomeBanner />

  <!--News, Notices, Event Area Start-->
  <div class="eduction-area pb-70">
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div
          class="col-lg-3 col-md-6"
          data-aos-duration="1200"
          data-aos-delay="200"
          data-aos-once="true"
        >
        <a href="https://rdc.eshala.co.in/page/notices">
          <div class="single-education-card bg-1">
            <div class="edication-content">
              <div class="icon">
                <i class="flaticon-messenger"></i>
              </div>
              
                <h3>Important Notices</h3>
              
            </div>
          </div>
          </a>
        </div>
        <div
          class="col-lg-3 col-md-6"
          data-aos-duration="1200"
          data-aos-delay="400"
          data-aos-once="true"
        >
        <a href="https://rdc.eshala.co.in/events">
          <div class="single-education-card bg-2">
            <div class="edication-content">
              <div class="icon">
                <i class="flaticon-advertising"></i>
              </div>
              
                <h3>Upcoming Events</h3>
              
            </div>
          </div>
          </a>
        </div>

        <div
          class="col-lg-3 col-md-6"
          data-aos-duration="1200"
          data-aos-delay="600"
          data-aos-once="true"
        >
          <a href="https://rdc.eshala.co.in/page/news">
            <div class="single-education-card bg-3">
              <div class="edication-content">
                <div class="icon">
                  <i class="flaticon-clock"></i>
                </div>
                <h3>Latest News</h3>
              </div>
            </div>
          </a>
        </div>

        <div
          class="col-lg-3 col-md-6"
          data-aos-duration="1200"
          data-aos-delay="800"
          data-aos-once="true"
        >
          <router-link to="/achievements">
            <div class="single-education-card bg-4">
              <div class="edication-content">
                <div class="icon">
                  <i class="flaticon-athletics"></i>
                </div>
                <h3>Achievements</h3>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!--News, Notices, Event Area End-->

  <!--Start Campus Information-->
  <div class="campus-information-area pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="campus-image">
            <img src="/vf/images/home/institute.png" alt="Image" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="campus-content style-2">
            <div class="campus-title">
              <h2>
                Welcome to <br />
                Vedanta P.G. Girls College
              </h2>
              <p>
                At Vedanta Post Graduate Girls College, education knows no
                bounds and dreams find wings to soar. The college strives to
                empower women and embolden their voices. The college offers
                undergraduate as well as postgraduate courses in the streams of
                Arts, Science, and Commerce. Along with providing quality
                education, Vedanta P.G Ringus college does the complementary
                action of spurring each student to discover their latent
                potential.
              </p>
            </div>
            <div class="list">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>A Not-for-Profit Institution</p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>Subsidised Fees</p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>Learn from the Best</p>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6">
                  <ul>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>Certification Courses</p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>Seminars and Workshop</p>
                    </li>
                    <li>
                      <i class="ri-check-fill"></i>
                      <p>Industry Connect</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="counter">
              <div class="row">
                <div class="col-lg-4 col-4">
                  <div class="counter-card">
                    <h1>
                      <span class="odometer" data-count="30">30</span>
                      <span class="target">+</span>
                    </h1>
                    <p>Affiliated Courses</p>
                  </div>
                </div>
                <div class="col-lg-4 col-4">
                  <div class="counter-card">
                    <h1>
                      <span class="odometer" data-count="30">30</span>
                      <span class="target heading-color">k</span
                      ><span class="target">+</span>
                    </h1>
                    <p>Student Graduates</p>
                  </div>
                </div>
                <div class="col-lg-4 col-4">
                  <div class="counter-card">
                    <h1>
                      <span class="odometer" data-count="30">30</span>
                      <span class="target">+</span>
                    </h1>
                    <p>Years of Experience</p>
                  </div>
                </div>
              </div>
            </div>
            <router-link to="/applynow" class="default-btn btn"
              >Start your application<i class="flaticon-next"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Campus Information-->

  <!-- Benefit Info -->
  <div>
    <section
      class="steps-area p-relative"
      style="background-color: #111d5e; display: block"
    >
      <div class="animations-10">
        <img src="/images/an-img-10.png" alt="an-img-01" />
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div
              class="section-title_2 mb-35 mt-35 wow fadeInDown animated"
              data-animation="fadeInDown"
              data-delay=".4s"
              style="visibility: visible; animation-name: fadeInDown"
            >
              <h2 style="font-size: 42px">Three Best Features</h2>
              <p>Discover Distinctive Advantages at Vedanta College</p>
            </div>
            <ul class="pr-20 pb-20">
              <li>
                <div
                  class="step-box wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                  style="visibility: visible; animation-name: fadeInUp"
                >
                  <div class="dnumber">
                    <div class="date-box">1</div>
                  </div>
                  <div class="text">
                    <h3>Affordable Fees</h3>
                    <p>Learn at a Subsidized Fees and Fuel Your Dreams</p>
                  </div>
                </div>
              </li>
              <li>
                <div
                  class="step-box wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                  style="visibility: visible; animation-name: fadeInUp"
                >
                  <div class="dnumber">
                    <div class="date-box">2</div>
                  </div>
                  <div class="text">
                    <h3>Learn beyond classrooms</h3>
                    <p>
                      Expand Your Horizons with Add-on Courses and Engaging
                      Workshop
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div
                  class="step-box wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                  style="visibility: visible; animation-name: fadeInUp"
                >
                  <div class="dnumber">
                    <div class="date-box">3</div>
                  </div>
                  <div class="text">
                    <h3>Industry Connect</h3>
                    <p>
                      Experience Real-World Learning with Industry Field Visit
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-12">
            <div
              class="step-imgx wow fadeInLeft animated image-container"
              data-animation="fadeInLeft"
              data-delay=".4s"
              style="visibility: visible; animation-name: fadeInLeft"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- Benefit Info -->

  <!--Start Courses Area-->
  <div class="courses-area pt-100 pb-70 bg-f4f6f9">
    <div class="container">
      <div class="section-title">
        <h2>Departments</h2>
        <p>
          Providing a wide array of UG/PG programs to suit your academic goals.
        </p>
      </div>
      <carousal-comp-type-one :content-info="deptInfo" />
    </div>
  </div>
  <!--End Courses Area-->

  <!-- Campus start -->
  <div class="campus-life-area ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>The Campus</h2>
        <p>
          Nestled in the Heart of Reengus, Vedanta PG College's Campus: Your
          Gateway to Educational Excellence.
        </p>
      </div>
      <div class="campus-slider2">
        <carousal-comp-type-two :content-info="campusInfo" />
      </div>
    </div>
  </div>
  <!-- Campus end -->

  <!--Start Admisssion Area-->
  <div class="admisssion-area ptb-100 admission-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="admission-left-content">
            <h2>Your Learning Starts Today!</h2>
            <p>
              We believe that a well-rounded education is essential, and offer
              our students the opportunity to get involved in a variety of
              activities that interest them.
            </p>
            <router-link  to="/applynow" class="default-btn btn"
              >Enroll Today and Embrace Success<i class="flaticon-next"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="admission-right-content">
            <ul>
              <li>
                <p>Watch the initiatives of <br />Vedanta Foundation</p>
              </li>
              <li>
                <div class="icon">
                  <a
                    class="popup-youtube play-btn"
                    href="https://www.youtube.com/watch?v=7uwKoiaZRf0"
                    ><i class="ri-play-fill"></i
                  ></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Admisssion Area-->

  <!--Start Student Life Area-->
  <div class="student-life-area pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Student Life @ Vedanta P.G. College</h2>
        <p>Where Learning and Lifelong Memories Unite.</p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="student-life-card">
            <img src="/vf/images/home/SL1.png" alt="Image" />
            <div class="tags">
              <p>Experiential Learning</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="student-life-card">
            <img src="/vf/images/home/SL2.png" alt="Image" />
            <div class="tags">
              <p>Industry Visit</p>
            </div>
          </div>
          <div class="student-life-card">
            <img src="/vf/images/home/SL3.png" alt="Image" />
            <div class="tags">
              <p>Seminars & Workshops</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Student Life Area-->

  <!--Start Check Student Stories-->
  <div class="students-stories-area pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Check Our stories</h2>
        <p>
          Where Excellence Meets Inspiration: Discover Success Stories at
          Vedanta PG Girls' College
        </p>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-lg-4 col-md-6 aos-init aos-animate"
          data-aos-duration="1200"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="single-stories-card style2">
            <div class="stories-content">
              <h3>Neetu Dhayal | Gold Medalists</h3>
            </div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/rh3DUdEG7MU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 aos-init aos-animate"
          data-aos-duration="1200"
          data-aos-delay="400"
          data-aos-once="true"
        >
          <div class="single-stories-card style2">
            <div class="stories-content">
              <h3>Ritu Dhayal | Volleyball Champ</h3>
            </div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-pfKyFdGF7c"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 aos-init aos-animate"
          data-aos-duration="1200"
          data-aos-delay="600"
          data-aos-once="true"
        >
          <div class="single-stories-card style2">
            <div class="stories-content">
              <h3>Maya | Volleyball Champ</h3>
            </div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/j_4f8fuWDI8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Check Student Stories-->

  <!--  Start Collaboration Area -->
  <div>
    <div class="section-title">
      <h3>Top Collaborations and Partners</h3>
      <p>Elevating Futures, Empowering Careers</p>
    </div>
    <div class="brand-area pt-30 pb-30">
      <div class="container">
        <div class="recruiter mb-20">
          <carousal-comp-type-three :content-info="recruiterInfo" />
        </div>
      </div>
    </div>
  </div>
  <!--  End Collaboration Area -->

  <!-- Start Modal -->
  <div class="card flex justify-content-center">
    <Dialog v-model:visible="visible" header="Admission Started!!" modal>
      <img src="/vf/images/popup_banner_1.png" />

      <div class="flex justify-content-end gap-2">
        <div class="interview" style="text-align: center">
          <a
            href="https://rdc.eshala.co.in/page/enquiry"
            class="default-btn btn"
            style="margin: 1rem"
            >Enquire Now</a
          ><router-link
            to="/applynow"
            style="margin: 1rem"
            class="default-btn btn"
            >Apply Now</router-link
          >
        </div>
      </div>
    </Dialog>
  </div>

  <!-- Modal End -->
</template>

<script>
import HomeBanner from "@/components/HomeBanner";
import CarousalCompTypeOne from "@/components/CarousalCompTypeOne.vue";
import CarousalCompTypeTwo from "@/components/CarousalCompTypeTwo.vue";
import CarousalCompTypeThree from "@/components/CarousalCompTypeThree.vue";
import Dialog from "primevue/dialog";

export default {
  name: "HomePage",
  components: {
    HomeBanner,
    CarousalCompTypeOne,
    CarousalCompTypeTwo,
    CarousalCompTypeThree,
    Dialog,
  },
  data() {
    return {
      displayLoader: true,
      visible: true,
      deptInfo: [
        {
          name: "Department of Arts",
          image: "/vf/images/home/D1.png",
          url: "academics/arts_department.html",
          description:
            "Department of Arts offers a diverse range of graduate and undergraduate courses in English and Hindi literature, fine arts, geography, and political science.",
        },
        {
          name: "Department of Commerce",
          image: "/vf/images/home/D2.png",
          url: "academics/comm_department.html",
          description:
            "Department of Commerce offers exciting opportunities in finance, human resources, marketing, and more. Elevate your career and knowledge in the world of commerce with us.",
        },
        {
          name: "Department of Science",
          image: "/vf/images/home/D3.png",
          url: "academics/sci_department.html",
          description:
            "Department of Science provides an enriching and stimulating environment for students pursuing graduate and post graduate courses in Botany, Chemistry, Maths, and Biology.",
        },
      ],
      campusInfo: [
        {
          title: "Smart Classrooms",
          image: "/vf/images/home/C1.png",
          url: "#",
          subtitle: "Education through digital pedagogy",
        },
        {
          title: "Library",
          image: "/vf/images/home/C2.png",
          url: "#",
          subtitle: "Explore a world of knowledge",
        },
        {
          title: "Hostel Facilities",
          image: "/vf/images/home/C3.png",
          url: "#",
          subtitle: "Modern & State-of-the-art Hostel",
        },
        {
          title: "Sports and Fitness",
          image: "/vf/images/home/C5.png",
          url: "#",
          subtitle: "Health with academic excellence",
        },
        {
          title: "Science Laboratories",
          image: "/vf/images/home/C6.png",
          url: "#",
          subtitle: "Lab fueling curiosity and innovation",
        },
        {
          title: "IT Laboratory",
          image: "/vf/images/home/C7.png",
          url: "#",
          subtitle: "Experience cutting-edge Technology",
        },
      ],
      recruiterInfo: [
        {
          image: "/vf/images/home/partners/j1.png",
        },
        {
          image: "/vf/images/home/partners/j2.png",
        },
        {
          image: "/vf/images/home/partners/j3.png",
        },
        {
          image: "/vf/images/home/partners/j4.png",
        },
        {
          image: "/vf/images/home/partners/j5.png",
        },
      ],
    };
  },
  mounted() {
    this.displayLoader = false;
  },
};
</script>
