<template>
     <!--Start Page Banner-->
     <div class="page-banner-area bg-1">
            <div class="container">
                <div class="page-banner-content">
                    <h1>Programs Offered</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Admission</li>
                        <li>Programs Offered</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--End Page Banner-->

         <!-- Start program -->
         <div class="academics-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Arts Programs</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="academics-left-content">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-art"></i>
                                        </div>
                                        <a href="../admission/ba.html">
                                            <h3>B.A (General)</h3>
                                        </a>
                                        <p>Under Graduate Program</p>
                                        <a href="../admission/ba.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-art"></i>
                                        </div>
                                        <a href="../admission/ma_draw.html">
                                            <h3>M.A (DRAWING & PAINTING)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/ma_draw.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-art"></i>
                                        </div>
                                        <a href="../admission/ma_english.html">
                                            <h3>M.A (ENGLISH LITERATURE)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/ma_english.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-art"></i>
                                        </div>
                                        <a href="../admission/ma_geo.html">
                                            <h3>M.A (GEOGRAPHY)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/ma_geo.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-art"></i>
                                        </div>
                                        <a href="../admission/ma_hin.html">
                                            <h3>M.A (HINDI LITERATURE)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/ma_hin.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-art"></i>
                                        </div>
                                        <a href="../admission/ma_pol.html">
                                            <h3>M.A (POLITICAL SCIENCE)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/ma_pol.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-art"></i>
                                        </div>
                                        <a href="../admission/ma_pa.html">
                                            <h3>M.A (PUBLIC ADMINISTRATION)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/ma_pa.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <hr />
        <div class="academics-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Commerce Programs</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="academics-left-content">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-money-exchange"></i>
                                        </div>
                                        <a href="../admission/bcom.html">
                                            <h3>B.Com (General)</h3>
                                        </a>
                                        <p>Under Graduate Program</p>
                                        <a href="../admission/bcom.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-money-exchange"></i>
                                        </div>
                                        <a href="../admission/mcom_abst.html">
                                            <h3>M.Com (ABST)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/mcom_abst.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-money-exchange"></i>
                                        </div>
                                        <a href="../admission/mcom_eafm.html">
                                            <h3>M.Com (EAFM)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/mcom_eafm.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="academics-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Science Programs</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="academics-left-content">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-heartbeat"></i>
                                        </div>
                                        <a href="../admission/bsc_bio.html">
                                            <h3>B.Sc (Biology)</h3>
                                        </a>
                                        <p>Under Graduate Program</p>
                                        <a href="../admission/bsc_bio.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-heartbeat"></i>
                                        </div>
                                        <a href="../admission/bsc_maths.html">
                                            <h3>B.Sc (Maths)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/bsc_maths.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-heartbeat"></i>
                                        </div>
                                        <a href="../admission/msc_chemistry.html">
                                            <h3>M.Sc (Chemistry)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/msc_chemistry.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-heartbeat"></i>
                                        </div>
                                        <a href="../admission/msc_botany.html">
                                            <h3>M.Sc (Botany)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/msc_botany.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-heartbeat"></i>
                                        </div>
                                        <a href="../admission/msc_maths.html">
                                            <h3>M.Sc (Maths)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/msc_maths.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="single-academics-card3">
                                        <div class="icon">
                                            <i class="flaticon-heartbeat"></i>
                                        </div>
                                        <a href="../admission/msc_physics.html">
                                            <h3>M.Sc (Physics)</h3>
                                        </a>
                                        <p>Post Graduate Program</p>
                                        <a href="../admission/msc_physics.html" class="read-more-btn">Read More<i class="flaticon-next"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End program end -->

        <!-- Start Bottom Connect Area -->
        <div class="alumni-interview-area pb-100">
            <div class="container">
                <div class="interview-content ptb-100">
                    <div class="interview">
                        <a href="../admission/eligibility.html" class="default-btn btn">Eligiblity Criteria<i
                                class="flaticon-next"></i></a>
                        <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name : "ProgramOfferedView"
};
</script>
