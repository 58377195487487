<template>
  <!-- Top header -->
  <header class="header-area header-three">
    <div class="header-top second-header d-none d-md-block">
      <div class="containerx">
        <div class="row align-items-center" style="--bs-gutter-x: 0px">
          <div class="col-lg-3 col-md-3 d-none d-lg-block">
            <div class="header-social">
              <a class="navbar-brand" href="/">
                <img
                  src="/vf/images/logo/vf_logo.png"
                  class="main-logo"
                  alt="logo"
                />
              </a>
              <!--  /social media icon redux -->
            </div>
          </div>
          <div class="col-lg-8 col-md-8 d-none d-lg-block text-right">
            <div class="header-right-content">
              <div class="list">
                <ul>
                  <li>
                    <a
                      href="https://rdc.eshala.co.in/welcome/library_books"
                      target="_blank"
                      >Library</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://rdc.eshala.co.in/site/login"
                      target="_blank"
                      >Staff Login</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://rdc.eshala.co.in/site/userlogin"
                      target="_blank"
                      >Student Login</a
                    >
                  </li>
                  <li><router-link to="/applynow">Admission</router-link></li>
                  <li><router-link to="/gallery">Gallery</router-link></li>
                  <li><router-link to="/placement">Placements</router-link></li>
                  <li><router-link to="/contact">Contact Us</router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- Start Navbar Area -->

  <div class="navbar-area nav-bg-2">
    <!-- Mobile -->
    <div class="mobile-responsive-nav">
      <div class="container">
        <div class="mobile-responsive-menu">
          <div class="logo">
            <a href="/">
              <!-- <img src="./images/vf/images/logo.png" class="main-logo" lt="logo">
              <img src="/vf/images/logo.png" class="white-logo" alt="logo"> -->
              <img
                src="/vf/images/logo/vf_logo.png"
                class="main-logo"
                lt="logo"
              />
              <img
                src="/vf/images/logo/vf_logo.png"
                class="white-logo"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Desktop -->
    <div class="desktop-nav">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-md navbar-light">
          <a class="navbar-brand" href="/">
            <img src="/vf/images/logo.png" class="main-logo" alt="logo" />
            <img src="/vf/images/logo.png" class="white-logo" alt="logo" />
          </a>
          <div
            class="collapse navbar-collapse mean-menu"
            id="navbarSupportedContent"
            style="margin-right: 10rem"
          >
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <a href="/" target="_self" class="nav-link">Home</a>
              </li>
              <!-- about -->
              <li class="nav-item">
                <a href="#" class="nav-link dropdown-toggle"> About </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a
                      href="https://vedantafoundation.org/"
                      class="nav-link"
                      target="_blank"
                      >Vedanta Foundation</a
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/aboutcollege">About College</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/campus">Campus</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/leadershipmessage"
                      >Leadership Message</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/principalmessage"
                      >Principal Message</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/affiliation">Affiliation</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/organogram">Organogram</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/governingbody"
                      >Governing Body</router-link
                    >
                  </li>
                </ul>
              </li>

              <!-- Academics -->
              <li class="nav-item">
                <a href="#" class="nav-link dropdown-toggle"> Academics </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/department">Departments</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/syllabus">Syllabus</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/subjects">Subjects</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/vocationalcenter"
                      >Vocational Center</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/academiccalender"
                      >Academic Calender</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/teachingstaff"
                      >Teaching Staff</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/nonteachingstaff"
                      >Non-Teaching Staff</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/collaboration"
                      >Collaboration &amp; MOU</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/eResources">eResources</router-link>
                  </li>
                </ul>
              </li>

              <!-- Admission -->
              <li class="nav-item">
                <a href="#" class="nav-link dropdown-toggle"> Admission </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/applynow" class="nav-link"
                      >Apply Now</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/programoffered" class="nav-link"
                      >Programs Offered</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/eligiblitycriteria" class="nav-link"
                      >Eligiblity criteria</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/feedetails" class="nav-link"
                      >Fee details</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/scholarship" class="nav-link"
                      >Scholarship</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/collegebrochure" class="nav-link"
                      >College Brochure</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/admissioncommittee" class="nav-link"
                      >Admission Committee</router-link
                    >
                  </li>
                </ul>
              </li>

              <!-- Student Corner -->
              <li class="nav-item">
                <a href="#" class="nav-link dropdown-toggle">
                  Student Corner
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/achievements" class="nav-link"
                      >Achievements</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/alumni" class="nav-link"
                      >Alumni</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/placement" class="nav-link"
                      >Placements</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/cells" class="nav-link"
                      >Cells</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/policies" class="nav-link"
                      >Policies</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="https://rdc.eshala.co.in/events"
                      target="_blank"
                      class="nav-link"
                      >Events</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="https://rdc.eshala.co.in/page/notices"
                      target="_blank"
                      class="nav-link"
                      >Notices</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="https://rdc.eshala.co.in/page/news"
                      target="_blank"
                      class="nav-link"
                      >News</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="https://rdc.eshala.co.in/site/userlogin"
                      target="_blank"
                      class="nav-link"
                      >Student Login</a
                    >
                  </li>
                </ul>
              </li>

              <!-- Publication -->
              <li class="nav-item">
                <a href="#" class="nav-link dropdown-toggle"> Publication </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a
                      href="https://vjrms.vpggc.ac.in/"
                      target="_blank"
                      class="nav-link"
                      >Vedanta Journal of Research and Multidisciplinary
                      Studies</a
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/newsletter" class="nav-link"
                      >Newsletter</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/media" class="nav-link"
                      >News Media</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/editorialboard" class="nav-link"
                      >Editorial Board</router-link
                    >
                  </li>
                </ul>
              </li>

              <!-- IQAC -->
              <li class="nav-item">
                <router-link to="#" class="nav-link dropdown-toggle">
                  IQAC
                </router-link>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a
                      href="vf/documents/naac/others/About_IQAC.pdf"
                      target="_blank"
                      class="nav-link"
                      >About IQAC</a
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/iqaccomposition" class="nav-link"
                      >IQAC Composition</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/iqacmeeting" class="nav-link"
                      >IQAC Meetings</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/iqacnotices" class="nav-link"
                      >IQAC Notices</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/aqar" class="nav-link">AQAR</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/clubCommittee"
                      >Clubs & Committees</router-link
                    >
                  </li>
                </ul>
              </li>

              <!-- NAAC -->
              <li class="nav-item">
                <a href="#" class="nav-link dropdown-toggle"> NAAC </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/naaccertificate" class="nav-link"
                      >NAAC Certificate</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/aishe" class="nav-link"
                      >AISHE</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      to="/institutionaldistinctiveness"
                      class="nav-link"
                      >Institutional Distinctiveness</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/bestpractises" class="nav-link"
                      >Best Practices
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/criteria" class="nav-link"
                      >Criteria</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/peerreview" class="nav-link"
                      >Peer Review</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>

    <div class="others-option-for-responsive">
      <div class="container">
        <div class="dot-menu">
          <div class="inner"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Navbar Area -->
</template>

<script>
export default {
  name: "AppHeader"
};
</script>

<style scoped>
/* Styles specific to the Header component */
</style>
