<!-- CarousalCompTypeTwo : Image, Small Header, large sub-header, link -->
<template>
  <Carousel :items-to-show="3" :wrap-around="true" :items-to-scroll="1" :transition="3000" :autoplay="3000">
    <Slide v-for="(info, index) in contentInfo" :key="index">
      <div class="single-campus-card">
        <div class="img">
          <router-link :to=info.url><img :src=info.image alt="Image"></router-link>
        </div>
        <div class="campus-content">
          <span>{{ info.title }}</span>
          <router-link :to=info.url>
            <h3>{{ info.subtitle }}</h3>
          </router-link>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
// import carousel from 'vue-owl-carousel'
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "CarousalCompTypeTwo",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {

    };
  },
  props: {
    contentInfo: {
      type: Array,
      required: true
    }
  }
};
</script>
