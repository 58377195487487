<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Syllabus</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Academics</li>
                    <li>Syllabus</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Alumni Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="alumni-left-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="https://www.shekhauni.ac.in/Syllabus1.aspx" target="_blank"><i
                                            class="flaticon-search"></i>Shekawati University<span
                                            style="float: right;"><strong>Click Here</strong></span></a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="alumni-right-content">
                        <div class="alumni-left-content">
                            <div class="related-download">
                                <ul>
                                    <li>
                                        <a href="https://www.uniraj.ac.in/index.php?mid=3102" target="_blank"><i
                                                class="flaticon-search"></i>
                                            Rajasthan University<span style="float: right;"><strong>Click
                                                    Here</strong></span></a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Alumni Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/subjects" class="default-btn btn">Subjects<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "SyllabusView"
};
</script>
