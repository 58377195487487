<!-- HomeBanner.vue -->
<template>
    <Carousel :autoplay="5000" :wrap-around="true" :perPage="1" :transition="2000">
        <Slide v-for="(image, index) in slideImages" :key="index">
            <img :src="image" alt="images" />
        </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>

// import carousel from 'vue-owl-carousel'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'



export default {
name: 'HomeBanner',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
    slideImages : [
        '/vf/images/home/B1.png','/vf/images/home/B2.png','/vf/images/home/B3.png'
    ]
    }
  }
};
</script>
