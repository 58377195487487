<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Scholarships</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Admission</li>
                    <li>Scholarships</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!-- Start Scholarship Area -->
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="row align-items-center">

                <!-- <div class="col-lg-4">
                        <div class="faq-image pr-20">
                            <img src="../assets/images/faq-img.jpg" alt="Image">
                        </div>
                    </div> -->
                <div class="col-lg-12">
                    <div class="faq-left-content pl-20">
                        <div class="faq-title">
                            <h2>Latest Scholarship Details</h2>
                            <p>The following fee concessions are only for the newly admitted girl students of
                                undergraduate courses:</p>
                        </div>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Government Scholarship
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div>
                                            <p><strong>Eligiblity</strong></p>
                                            <ul>
                                                <li>100% fees waiver whose both parents have died suddenly due to Corona
                                                </li>
                                                <li>50% rebate In fees in case of sudden demise of one of the parents
                                                    due to Corona</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p><strong>Documents</strong></p>
                                            <ul>
                                                <li>Mark sheet of Secondary Examination or its equivalent (Both
                                                    sides)</li>
                                                <li>Mark sheet of last Board/Council/University/College examination
                                                    (Both sides)</li>
                                                <li>Admission receipt</li>
                                                <li>Income certificate of family </li>
                                                <li>Domicile certificate as Aadhaar ID/Voter ID/Ration
                                                    card/Certificate Issued by concerned authority</li>
                                                <li>Scanned copy of Bank Passbook (1st Page, containing A/C No. and
                                                    IFSC)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Vedanta Foundation Scholarship
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div>
                                            <p><strong>Eligiblity</strong></p>
                                            <ul>
                                                <li>100% fee relaxation for girl students belonging to the families of
                                                    martyred soldiers</li>
                                                <li>75% rebate on participation in sports at the national level in the
                                                    last session and 50% rebate on participation in sports at the state
                                                    level</li>
                                                <li>Provision of 75% relaxation to girl students scoring 90-100 percent
                                                    marks</li>
                                                <li>50% relaxation in fees for the girl students who are close relatives
                                                    of martyred soldiers</li>
                                                <li>50% relaxation in fees for the girl students from families of
                                                    military/ paramilitary forces</li>
                                                <li>Provision of 40% relaxation to girl students scoring 85-90 percent
                                                    marks</li>
                                                <li>Provision of 30% discount to the girl students from BPL families
                                                </li>
                                                <li>Provision of 25% relaxation to girl students scoring 75-85 percent
                                                    marks</li>

                                            </ul>
                                        </div>
                                        <div>
                                            <p><strong>Documents</strong></p>
                                            <ul>
                                                <li>Passport Size Photograph</li>
                                                <li>Caste certificate,Birth certificate,Income certificate,
                                                    Residential Certificate, any Government identity Proof</li>
                                                <li>Last year qualifying exam marks sheet</li>
                                                <li>Income certificate of family </li>
                                                <li>Scanned copy of Bank Passbook (1st Page, containing A/C No. and
                                                    IFSC)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- End Scholarship Area -->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/collegebrochure" class="default-btn btn">College Brochure<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "ScholarshipView"
};
</script>
