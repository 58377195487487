<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
            <div class="container">
                <div class="page-banner-content">
                    <h1>Principal's Message</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>About</li>
                        <li>Principal's Message</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--End Page Banner-->

        <!-- MAIN start -->

        <section class="team-area-content">
            <div class="container"> <!-- Lower Content -->
                <div class="lower-content">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 col-sm-12">
                            <div class="team-img-box"> <img width="460" height="500"
                                    src="/vf/images/general/principal.png"
                                    class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt=""
                                    decoding="async" fetchpriority="high"></div>
                        </div>
                        <div class="text-column col-lg-7 col-md-12 col-sm-12">
                            <div class="s-about-content pl-30 wow fadeInRight" data-animation="fadeInRight"
                                data-delay=".2s" style="visibility: visible; animation-name: fadeInRight;">
                                <h2>Dr. Shubha Sharma</h2>
                                <p><i>Principal, Vedanta P.G. Girls College Reengus</i></p>
                                <h3>Message</h3>
                                <p>Vedanta P.G College is a beacon of empowerment and enlightenment nestled in the heart
                                    of Reengus, Rajasthan. At Vedanta, we believe in the transformative power of
                                    education, particularly for women in rural communities who often face barriers to
                                    pursuing their dreams.
                                    <br /><br />
                                    Our commitment to the investment, growth, and development of women's success is
                                    unwavering. From offering a diverse range of undergraduate to post-graduate courses
                                    in Arts, Science, and Commerce, to providing holistic skill enhancement training, we
                                    strive to equip our students with the tools they need to thrive in an ever-evolving
                                    world.
                                    <br /><br />
                                    At Vedanta P.G. Girls College, we are committed to providing students with
                                    comprehensive
                                    development opportunities, enabling them to realize their full potential not only in
                                    academia but also in extracurricular and co-curricular pursuits. Our institution
                                    supports students in cultivating 21st-century skills that enhance employability. We
                                    embark on a journey to unearth the possibilities within each student, honing their
                                    abilities, and equipping them for the evolving landscape of the future.
                                    <br /><br />
                                    Beyond academics, Vedanta P.G College fosters an environment that nurtures character
                                    and competence through a rich tapestry of co-curricular activities. Whether it's
                                    participating in debates, sports, cultural events, or community service projects,
                                    our students are encouraged to explore their passions and develop into well-rounded
                                    individuals.
                                    <br /><br />
                                    The fruits of our labor are evident in our alumni, who serve as trailblazers and
                                    change-makers in various fields. From government officials to educators, social
                                    reformers to entrepreneurs, Vedanta graduates are making a tangible difference both
                                    nationally and globally.
                                    <br /><br />
                                    As we continue to uphold our legacy of excellence in character development and
                                    academic prowess, we invite you to join us on this journey of empowerment and
                                    transformation. Together, let us shape a future where every woman has the
                                    opportunity to realize her full potential and contribute meaningfully to society.
                                    <br /><br />
                                    Welcome to Vedanta P.G College, where dreams take flight and success knows no
                                    bounds.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Start Bottom Connect Area -->
        <div class="alumni-interview-area pb-100">
            <div class="container">
                <div class="interview-content ptb-100">
                    <div class="interview">
                        <router-link href="/affiliation" class="default-btn btn">Affiliation<i
                                class="flaticon-next"></i></router-link>
                        <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--End Bottom Connect Area -->


        <!-- MAIN end -->
</template>

<script>
export default {
    name : "PrincipalMessageView"
};
</script>
