<template>
    <div>
        <h1>This is the IqacAboutView Page </h1>
    </div>
</template>

<script>
export default {
    name : "IqacAboutView"
};
</script>
