<template>
  <!--Start Footer Area-->
  <div class="footer-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="footer-logo-area">
            <a href="#"><img src="/vf/images/logo/footer_logo.png" alt="Image"></a>
            <p>Vedanta P.G Girls College is a Not-For-Profit institution, empowering women.
            </p>
            <div class="contact-list">
              <ul>
                <li><a href="tel:911575224549 ">(+91)01575- 224549 </a></li>
                <li><a href="mailto:principal.ringus@gmail.com"> principal.ringus@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="footer-widjet">
            <h3>Committee</h3>
            <div class="list">
              <ul>
                <li><router-link to="/collegecommittee">College Committee</router-link></li>
                <li><router-link to="/wdc">Women Development Cell</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="footer-widjet">
            <h3>Compliances</h3>
            <div class="list">
              <ul>
                <li><router-link href="/antiragging">Anti Ragging</router-link></li>
                <li><router-link href="/bestpractices">Best Practices</router-link></li>
                <li><router-link href="/codeofconduct">Code of Conduct</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6">
          <div class="footer-widjet">
            <h3>Other Links</h3>
            <div class="list">
              <ul>
                <li><router-link to="/contact">Contact</router-link></li>
                <!-- <li><router-link href="#">Privacy Policy</router-link></li>
                <li><router-link href="#">Terms & Conditions</router-link></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="shape">
        <img src="/images/shape-1.png" alt="Image">
      </div>
    </div>
  </div>
  <!--End Footer Area-->

  <!--Start Copyright Area-->
  <div class="copyright-area">
    <div class="container">
      <div class="copyright">
        <div class="row">
          <div class="col-lg-6 col-md-4">
            <div class="social-content">
              <ul>
                <li><span>Follow Us On</span></li>
                <li>
                  <a href="https://www.facebook.com" target="_blank"><i class="ri-facebook-fill"></i></a>
                </li>
                <li>
                  <a href="https://www.twitter.com" target="_blank"><i class="ri-twitter-fill"></i></a>
                </li>
                <li>
                  <a href="https://instagram.com/?lang=en" target="_blank"><i class="ri-instagram-line"></i></a>
                </li>
                <li>
                  <a href="https://linkedin.com/?lang=en" target="_blank"><i class="ri-linkedin-fill"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-8">
            <div class="copy">
              <p>© Vedanta P.G. Girls College, Reengus is Proudly Owned by <a href="https://vedantafoundation.org"
                  target="_blank">Vedanta Foundation</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Copyright Area-->

  
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
/* Styles specific to the Footer component */
</style>