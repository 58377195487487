<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
            <div class="container">
                <div class="page-banner-content">
                    <h1>Community Vocational Center</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Academics</li>
                        <li>Community Vocational Center</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--End Page Banner-->

        <div class="academic-area pt-70">
            <div class="section-title">
                <h2>Unlock New Opportunities</h2>
                <p>Vedanta College Community Center is dedicated to empower the community with affordable skill training programs .
                </p>
            </div>
        </div>

        <!--Start Alumni Area-->
        <div class="alumni-area pt-50 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="alumni-left-content">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/vf/images/certification/1.png" alt="Image">
                                            <div class="date">
                                                <p>Certificate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong>Duration: </strong>6 months</p>
                                            </div>
                                            <h3>Professional Beautician Course</h3>
                                            <div class="location">
                                                <p><i class="ri-money-cny-box-line"></i><strong>Fees: </strong>₹ 2100
                                                    only</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/vf/images/certification/3.png" alt="Image">
                                            <div class="date">
                                                <p>Certificate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong>Duration: </strong>3 months</p>
                                            </div>
                                            <h3>Fashion Designer Course</h3>
                                            <div class="location">
                                                <p><i class="ri-money-cny-box-line"></i><strong>Fees: </strong>₹ 1600
                                                    only</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/vf/images/certification/5.png" alt="Image">
                                            <div class="date">
                                                <p>Certificate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong>Duration: </strong>9 months</p>
                                            </div>
                                            <h3><strong>ADCA</strong> (Advanced Diploma in Computer Application)</h3>
                                            <div class="location">
                                                <p><i class="ri-money-cny-box-line"></i><strong>Fees: </strong>₹ 3100
                                                    only</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/vf/images/certification/7.png" alt="Image">
                                            <div class="date">
                                                <p>Certificate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong>Duration: </strong>6 months</p>
                                            </div>
                                            <h3><strong>DCA</strong> (Diploma in Computer Application)</h3>
                                            <div class="location">
                                                <p><i class="ri-money-cny-box-line"></i><strong>Fees: </strong>₹ 2100
                                                    only</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="alumni-right-content">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/vf/images/certification/2.png" alt="Image">
                                            <div class="date">
                                                <p>Certificate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong>Duration: </strong>3 months</p>
                                            </div>
                                            <h3><strong>CCA</strong> (Certificate in Computer Application)</h3>
                                            <div class="location">
                                                <p><i class="ri-money-cny-box-line"></i><strong>Fees: </strong>₹ 1600
                                                    only</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/vf/images/certification/4.png" alt="Image">
                                            <div class="date">
                                                <p>Certificate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong>Duration: </strong>3 months</p>
                                            </div>
                                            <h3><strong>CFA</strong> (Certificate in Financial Accounting
                                            </h3>
                                            <div class="location">
                                                <p><i class="ri-money-cny-box-line"></i><strong>Fees: </strong>₹ 1600
                                                    only</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="/vf/images/certification/6.png" alt="Image">
                                            <div class="date">
                                                <p>Certificate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <div class="time">
                                                <p><i class="flaticon-time"></i><strong>Duration: </strong>2 months</p>
                                            </div>
                                            <h3><strong>CDTP</strong> (Certificate in Desktop Publishing)</h3>
                                            <div class="location">
                                                <p><i class="ri-money-cny-box-line"></i><strong>Fees: </strong>₹ 200
                                                    only</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Alumni Area-->

        <!-- Start Bottom Connect Area -->
        <div class="alumni-interview-area pb-100">
            <div class="container">
                <div class="interview-content ptb-100">
                    <div class="interview">
                        <router-link href="/academiccalender" class="default-btn btn">Academic Calender<i
                                class="flaticon-next"></i></router-link>
                        <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--End Bottom Connect Area -->



</template>

<script>
export default {
    name : "VocationCenterView"
};
</script>
