<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Collaboration and MOU</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Academics</li>
                    <li>Collaboration and MOU</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Mou Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">

                    <div class="alumni-left-content">
                        <div class="related-download">
                            <ul>
                                <li>
                                    <a href="vf/documents/academics/colab_mou/Collaboration_mou.pdf" target="_blank"><i
                                            class="flaticon-pdf-file"></i>Collaboration and MOU<span
                                            style="float: right;"><strong>Download</strong></span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Mou Area-->

    <!-- Top recruiters -->
    <div>
        <div class="section-title">
            <h3>Top Collaborations and Partners</h3>
            <p>Elevating Futures, Empowering Careers</p>
        </div>
        <div class="brand-area pt-30 pb-30">
            <div class="container">
                <div class="recruiter mb-20">
                    <carousal-comp-type-three :content-info="recruiterInfo" />
                </div>
            </div>
        </div>
    </div>
    <!-- End recruiters -->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/eresources" class="default-btn btn">eResources<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->

</template>

<script>
import CarousalCompTypeThree from "@/components/CarousalCompTypeThree.vue";
export default {
    name: "CollaborationView",
    components: {
        CarousalCompTypeThree
    },
    data() {
        return {
            recruiterInfo: [
                {
                    "image": "/vf/images/home/partners/j1.png"
                },
                {
                    "image": "/vf/images/home/partners/j2.png"
                },
                {
                    "image": "/vf/images/home/partners/j3.png"
                },
                {
                    "image": "/vf/images/home/partners/j4.png"
                },
                {
                    "image": "/vf/images/home/partners/j5.png"
                }
            ]
        }
    }
};
</script>
