<template>
  <!--Start Page Banner-->
  <div class="page-banner-area bg-1">
    <div class="container">
      <div class="page-banner-content">
        <h1>Criteria</h1>
        <ul>
          <li><a href="/">Home</a></li>
          <li>NAAC</li>
          <li>Criteria</li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Page Banner-->

  <div class="alumni-area pt-70 pb-70">
    <div class="container">
      <div class="row">
        <div class="section-title">
          <h2>NAAC Criteria's</h2>
        </div>

        <div class="col-lg-6">
          <div>
            <div>
              <h3>Criteria 2020-2021</h3>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="c2021One">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-target="#collapse2021One"
                  >
                    Criteria 1
                  </button>
                </h2>
                <div
                  id="collapse2021One"
                  class="accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Feedback-Report-v-1.4.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C01_Feedback-Report-v-1.4.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="c2021Two">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2021Two"
                    
                    
                  >
                    Criteria 2
                  </button>
                </h2>
                <div
                  id="collapse2021Two"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2.3.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C02_2.3.2.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2.7</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C02_2.7.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2.7.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C02_2.7.1-SSS-2020-21.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2021Three"
                    
                    
                  >
                    Criteria 3
                  </button>
                </h2>
                <div
                  id="collapse2021Three"
                  class=" accordion-collapse collapse show"
                  
                  
                >
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>3.1-3.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C03_3.1-3.2-Number-of-Full-time-teachers.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.2.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C03_Ecosystem-v-3.2.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.2.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C03_3.2.2.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.4.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C03_Covid-19-Report-v-3.4.2.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.4.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C03_Criteria-3.4.3.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-target="#collapse2021Four"
                    
                    
                  >
                    Criteria 4
                  </button>
                </h2>
                <div
                  id="collapse2021Four"
                  class=" accordion-collapse collapse show"
                  
                  
                >
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>4.1.1-4.4.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C04_4.1.1-_-4.4.2-releted-pic.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.1.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C04_4.1.2-releted-pic.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.1.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C04_4.1.3.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C04_4.2-v-library-shaala-software-pic-2020-21.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.3.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C04_4.3.1-RELETED-PICS.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2021Five"
                    
                    
                  >
                    Criteria 5
                  </button>
                </h2>
                <div
                  id="collapse2021Five"
                  class=" accordion-collapse collapse show"
                  
                  
                >
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>5.1.3 ICT Computing Skills</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_criteria-v-5.1.3-ICT-computing-skills.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.1.3 Language Communication Skills</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_Criteria-v-5.1.3-language-Comunication-.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.1.3 Life Skills</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_Criteria-V-5.1.3-Life-Skills-1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.1.3 Soft Skills</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_criteria-v-5.1.3-Soft-skills-.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.1.3 Online Course Certificate</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_Online-Course-Certificate-Course-Broucher-v-5.1.3.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.1.5</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_criteria-v-5.1.5-Grievances-1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.3.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_Criteria-v-5.3.2-Clubs-pic.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.4.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C05_5.4.1-Alumni-Association.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2021Six"
                    
                    
                  >
                    Criteria 6
                  </button>
                </h2>
                <div
                  id="collapse2021Six"
                  class=" accordion-collapse collapse show"
                  
                  
                >
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>6.2.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C06_Institutional-Strategic_-Perspective-Plan-v-6.2.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>6.2.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C06_VF-Organization-Structure-v-6.2.2.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>6.5.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C06_collumpic-details-v-6.5.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>6.5.1-1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C06_Online-Certificate-Courses-Details-v-6.5.1_1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2021Seven"
                    
                    
                  >
                    Criteria 7
                  </button>
                </h2>
                <div
                  id="collapse2021Seven"
                  class=" accordion-collapse collapse show"
                  
                  
                >
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>7.1.1 - Women Development Cell</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Women-Development-Cell-v-7.1.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.3 - Nature Club</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Nature-Club-Criteria-7.1.3.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.3 - Best Out of Waste</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Comptetion-on-Best-out-of-Waste-v-7.1.3.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.8 - Departmental Activities</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Departmental-Activities-v-7.1.8.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.9 - NSS & Scout</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_NSS-and-Scout-v-7.1.9.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div>
            <div>
              <h3>Criteria 2022-2023</h3>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    
                    data-bs-target="#collapse2023One"
                    
                    
                  >
                    Criteria 1
                  </button>
                </h2>
                <div
                  id="collapse2023One"
                  class="accordion-collapse collapse show"
                  >
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particulars</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Feedback Report 2022-23</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C01_feedback_analysis_report.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2023Two"
                    
                    
                  >
                    Criteria 2
                  </button>
                </h2>
                <div
                  id="collapse2023Two"
                  class=" accordion-collapse collapse show"
                  
                >
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particulars</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2.3.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C02_2.3.2.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2.6.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C02_2.6.3_result_chart_2023.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2.7.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C02_2.7.1_SSS_Report_by_Graph.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2023Three"
                    
                    
                  >
                    Criteria 3
                  </button>
                </h2>
                <div
                  id="collapse2023Three"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particulars</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>3.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C03_3.2.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.2.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C03_3.2.2.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2.5.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C03_3.5.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2023Three"
                    
                    
                  >
                    Criteria 4
                  </button>
                </h2>
                <div
                  id="collapse2023Three"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particulars</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>4.1.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C04_4.1.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.1.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C04_4.1.2.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.1.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C04_4.1.3.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.1.3.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C04_4.1.3.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.2.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C04_4.2.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.3.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C04_4.3.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.4.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C04_4.4.2.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2023Three"
                    
                    
                  >
                    Criteria 5
                  </button>
                </h2>
                <div
                  id="collapse2023Three"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particulars</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>5.1.4</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C05_5.1.4.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.3.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C05_5.3.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2023Three"
                    
                    
                  >
                    Criteria 6
                  </button>
                </h2>
                <div
                  id="collapse2023Three"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particulars</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>6.2.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C06_6.2.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>6.2.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C06_6.2.2.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>6.5.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C06_6.5.3.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2023Three"
                    
                    
                  >
                    Criteria 7
                  </button>
                </h2>
                <div
                  id="collapse2023Three"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particulars</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>7.1.1 - Photos Cell</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.1.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.1 - WDC Cell</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.1_WDC_Cell.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.3 - Nature Club</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.3_Nature_Club.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.8 Departmental Activites</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.8_Departmental Activites.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.8 - Photos Activities</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.8 photos.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.9 - NSS & Scout</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.9_N.S.S_Scout_Guid.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.11 - Documents</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.11.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.11 - National & International</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/03_criteria_2022_23/C07_7.1.11_National_International.pdf"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div>
            <div>
              <h3>Criteria 2021-2022</h3>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    
                    data-bs-target="#collapse2022One"
                    
                    
                  >
                    Criteria 1
                  </button>
                </h2>
                <div
                  id="collapse2022One"
                  class="accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Feedback Report 2021-22</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C01_Feedback-Report-2021-22.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2022Two"
                    
                    
                  >
                    Criteria 2
                  </button>
                </h2>
                <div
                  id="collapse2022Two"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2.3.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C02_2.3.2.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2.6.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C02_2.6.3-Chart.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2.7.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C02_2.7.1-Chart.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2022Three"
                    
                    
                  >
                    Criteria 3
                  </button>
                </h2>
                <div
                  id="collapse2022Three"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>3.1.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.1.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.1.2-3.3.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.1.23.3.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.2.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.2.2-2021-22.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.3.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.2.2-2021-22.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.3.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.3.32021-22.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.4.3 - 3.4.4</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.4.13.4.3-3.4.4.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.5.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.5.1-final.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3.5.2</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C03_3.5.2.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2022Four"
                    
                    
                  >
                    Criteria 4
                  </button>
                </h2>
                <div
                  id="collapse2022Four"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>4.1.3</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C04_4.1.3-2.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4.2.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C04_21-22-library-sheela-softwar-v-4.2.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2022five"
                    
                    
                  >
                    Criteria 5
                  </button>
                </h2>
                <div
                  id="collapse2022five"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>5.1.3 Skills</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C05_5.1.3-Skills.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>5.4.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C05_5.4.1-final.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2022Six"
                    
                    
                  >
                    Criteria 6
                  </button>
                </h2>
                <div
                  id="collapse2022Six"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>6.5.1</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/02_criteria_2021_22/C06_6.5.1sports-activity-details-21-22-final.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    
                    data-bs-target="#collapse2022Seven"
                    
                    
                  >
                    Criteria 7
                  </button>
                </h2>
                <div
                  id="collapse2022Seven"
                  class=" accordion-collapse collapse show">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%">Particular</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>7.1.1 - Women Development Cell</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Women-Development-Cell-v-7.1.1.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.3 - Nature Club</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Nature-Club-Criteria-7.1.3.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.3 - Best Out of Waste</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Comptetion-on-Best-out-of-Waste-v-7.1.3.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.8 - Departmental Activities</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_Departmental-Activities-v-7.1.8.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>7.1.9 - NSS & Scout</td>
                          <td>
                            <a
                              href="vf/documents/naac/08_Criteria/01_criteria_2020_21/C07_NSS-and-Scout-v-7.1.9.pdf"
                              target="_blank"
                              >Download</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Criteria Area -->

  <!-- Start Bottom Connect Area -->
  <div class="alumni-interview-area pb-100">
    <div class="container">
      <div class="interview-content ptb-100">
        <div class="interview">
          <router-link to="/contact" class="default-btn btn">Contact<i class="flaticon-next"></i></router-link>
          <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- End Bottom Connect Area -->
</template>

<script>
export default {
  name: "CriteriaView",
};
</script>
<!-- <script>
export default {
    name: "CriteriaView",
    data() {
        return {
            data: null,
            right_info: '',
            left_info: ''
        };
    },
    methods: {
        async fetchData() {

            const responseValue = await fetch("json/best_practise.json");
            this.data = await responseValue.json();
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script> -->
