<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Affiliation</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>About</li>
                    <li>Affiliation</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Affiation Area-->
    <div class="alumni-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">

                        <div class="alumni-left-content">
                            <div class="related-download">
                                <ul>
                                    <li>
                                        <a href="/vf/documents/about/affiliation 2023-24.jpg"
                                            target="_blank"><i class="flaticon-pdf-file"></i>
                                            Affiliation<span style="float: right;"><strong>Download</strong></span></a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Affiation Area-->

        <!-- Start Bottom Connect Area -->
        <div class="alumni-interview-area pb-100">
            <div class="container">
                <div class="interview-content ptb-100">
                    <div class="interview">
                        <router-link to="/organogram" class="default-btn btn">Organogram<i
                                class="flaticon-next"></i></router-link>
                        <router-link to="/applynow" class="default-btn btn">Apply Now<i
                                class="flaticon-next"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "AffiliationView"
};
</script>
