<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
            <div class="container">
                <div class="page-banner-content">
                    <h1>Cells</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Student Corner</li>
                        <li>Cells</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--End Page Banner-->

        <!--Start Alumni Area-->
        <div class="alumni-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="section-title">
                        <h2>Cells and Clubs</h2>
                    </div>

                    <div class="col-lg-6">
                        <div class="alumni-left-content">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="vf/images/cells/C1.png" alt="Image">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>Women Development Cell</h3>
                                            <div class="location">
                                                <p>Supportive learning environment, fostering the holistic development
                                                    of female students.</p>
                                            </div>
                                            <a href="../studentcorner/wdc.html">Click Here</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="vf/images/cells/sport_club_c4.png" alt="Image">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>Sports Club</h3>
                                            <div class="location">
                                                <p>Incorporating both physical development through a selected sports and
                                                    mental growth through Yoga and Meditation practices.</p>
                                            </div>
                                        </div>
                                        <a href="../studentcorner/sports_club.html">Click Here</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="alumni-right-content">
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="vf/images/cells/C2.png" alt="Image">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>NSS Cell</h3>
                                            <div class="location">
                                                <p>The aim of the scheme is to develop students’ personality through
                                                    community service. </p>
                                            </div>
                                        </div>
                                        <a href="../studentcorner/nss.html">Click Here</a>
                                    </div>
                                </div>
                            </div>
                            <div class="single-alumni-events-card">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="alumni-img">
                                            <img src="vf/images/cells/C3.png" alt="Image">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="alumni-content">
                                            <h3>Scout & Guide</h3>
                                            <div class="location">
                                                <p>Scout /guide ( Ranger) team is a unit of Bharat scout guide.</p>
                                            </div>
                                        </div>
                                        <a href="../studentcorner/scout_guide.html">Click Here</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Alumni Area-->


        <!-- Start Bottom Connect Area -->
        <div class="alumni-interview-area pb-100">
            <div class="container">
                <div class="interview-content ptb-100">
                    <div class="interview">
                        <a href="../studentcorner/policies.html" class="default-btn btn">Policies<i
                                class="flaticon-next"></i></a>
                        <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name : "CellsView"
};
</script>
