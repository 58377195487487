<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Placements</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Student Corner</li>
                    <li>Placements</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->


    <!--Start placement Area-->
    <div class="alumni-area pt-100 pb-70">
        <div class="container">
            <h2 style="text-align: center;" class="pt-30">Students Placed</h2>
            <div class="alumni-area pt-30 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="alumni-left-content">

                                <div class="single-alumni-events-card">
                                    <div class="row align-items-center">
                                        <div class="col-lg-5 col-md-5">
                                            <div class="alumni-img"><img
                                                    src="vf/images/student_corner/alumni/9.png" alt="Image"
                                                    style="width:80%;"></div>
                                        </div>
                                        <div class="col-lg-7 col-md-7">
                                            <div class="alumni-content">
                                                <h3>Uma Soni</h3>
                                                <div class="location">
                                                    <p><i class="ri-medal-2-fill"></i><strong></strong>III Grade Teacher
                                                    </p>
                                                </div>
                                                <div class="location">
                                                    <p><i class="ri-graduation-cap-line"></i><strong></strong>M.A. (Pol.
                                                        Sci.)</p>
                                                </div>
                                                <div class="time">
                                                    <p><i class="flaticon-time"></i><strong></strong>Ace Ceramics</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="alumni-right-content">

                                <div class="single-alumni-events-card">
                                    <div class="row align-items-center">
                                        <div class="col-lg-5 col-md-5">
                                            <div class="alumni-img"><img
                                                    src="vf/images/student_corner/alumni/10.png" alt="Image"
                                                    style="width:80%;"></div>
                                        </div>
                                        <div class="col-lg-7 col-md-7">
                                            <div class="alumni-content">
                                                <h3>Abhilasha Bajiya</h3>
                                                <div class="location">
                                                    <p><i class="ri-medal-2-fill"></i><strong></strong>Village
                                                        Development Officer</p>
                                                </div>
                                                <div class="location">
                                                    <p><i class="ri-graduation-cap-line"></i><strong></strong>B.Sc.</p>
                                                </div>
                                                <div class="time">
                                                    <p><i class="flaticon-time"></i><strong></strong>Ace Ceramics</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--End Academic Area-->
        </div>
    </div>
    <!--End placement Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100 pt-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/cells" class="default-btn btn">Cells<i class="flaticon-next"></i></router-link>
                    <router-link to="/applynow" class="default-btn btn">Apply Now<i
                            class="flaticon-next"></i></router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->
</template>

<script>
export default {
    name: "PlacementView"
};
</script>
