<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Departments</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Academics</li>
                    <li>Departments</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Academic Area-->
    <div class="academic-area pt-100 pb-70 bg-f4f6f9">
        <div class="container">
            <div class="section-title">
                <h2>Departments</h2>
                <p>Navigate Through Our Diverse Departments</p>
            </div>
            <div class="row justify-content-center">
                <div v-for="(info, index) in this.contentInfo" :key="index" class="col-lg-4 col-md-6">
                    <div class="single-academics-card2 bg-3"
                        :style="{ backgroundImage: 'url(' + getImageUrl(info) + ')' }">
                        <div class="serial">
                            <p>{{ '0' + (index + 1) }}</p>
                        </div>
                        <div class="academic-content">
                            <div class="academic-top-content">
                                <i class="flaticon-graduation"></i>
                                <a href="../academics/sci_department.html">
                                    <h3>{{ info.title }}</h3>
                                </a>
                            </div>
                            <p>{{ info.subtitle }}</p>
                            <router-link class="read-more-btn white-color" :to="info.url">Know More<i
                                    class="flaticon-next"></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Academic Area-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/syllabus" class="default-btn btn">Syllabus<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "DepartmentView",
    data() {
        return {
            contentInfo: [
                {
                    'title': "Department of Arts",
                    'subtitle': "We believe in the transformative power of the arts to ignite imagination, foster critical thinking, and evoke a profound understanding of the human experience.",
                    'link': "/artsdepartment",
                    'image': "vf/images/department/d1.png"
                },
                {
                    'title': "Department of Commerce",
                    'subtitle': "Committed to nurturing future business leaders and financial experts, our department offers a comprehensive and contemporary curriculum.",
                    'link': "/commercedepartment",
                    'image': "vf/images/department/d2.png"
                },
                {
                    'title': "Department of Science",
                    'subtitle': "A dynamic hub where curiosity meets innovation, and students embark on a journey of exploration, intellectual growth and nurtures inquisitive minds.",
                    'link': "/sciencedepartment",
                    'image': "vf/images/department/d3.png"
                }


            ]
        }
    },
    methods: {
        getImageUrl(info) {
            return info.image;
        }
    }
};
</script>
