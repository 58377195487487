<template>
    <!--Start Page Banner-->
    <div class="page-banner-area bg-1">
        <div class="container">
            <div class="page-banner-content">
                <h1>Apply Now</h1>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Admission</li>
                    <li>Apply Now</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Page Banner-->

    <!--Start Graduate Admission-->
    <div class="graduate-admission pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="how-to-apply">
                        <h2>Details About How To Apply</h2>
                        <p>A candidate who have passed the Higher Secondary (10+2) or its equivalent examination is
                            eligible to
                            seek admission to the first year of the three-year B.A. / B.Sc. / B.Com. (Honours)
                            Courses provided
                            she has also passed in English, the full marks not being less than 100 and fulfil the
                            conditions given
                            below</p>

                        <div class="apply-list">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>Gather Necessary Documents</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Start Online Admission Process
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Read the Instruction</li>

                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>Fill the Online Admission Form
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Review the Form</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Make the Payment</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="submit">
                            <h3>Submit Your Application</h3>

                            <a href="https://rdc.eshala.co.in/online_admission" class="default-btn btn">Submit Your
                                Application<i class="flaticon-next"></i></a>
                        </div>

                        <div class="financial">
                            <h3>Financial Aid</h3>
                            <p>Recognizing the importance of accessible education, financial aid plays a pivotal
                                role in shaping the academic destinies of aspiring students. It serves as a bridge
                                between ambition and accomplishment, breaking down economic barriers that may hinder
                                the pursuit of knowledge. </p>
                            <p>Our robust financial aid program is designed to empower students, ensuring that no
                                dream is left unfulfilled due to financial constraints.</p>
                            <router-link to="/scholarship" class="default-btn btn">Check For Financial
                                Aid<i class="flaticon-next"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="additional-information">
                        <h3>Additional Information</h3>
                        <div class="list">
                            <ul>
                                <li><router-link to="/campus">Campus Tour</router-link></li>
                                <li><router-link to="/eligiblitycriteria">Eligiblity Criteria</router-link></li>
                                <li><router-link to="/feedetails">Fee Details</router-link></li>
                                <li><router-link to="syllabus">Syllabus</router-link></li>
                                <li><router-link to="/subjects">Subjects</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Graduate Admission-->

    <!-- Start Bottom Connect Area -->
    <div class="alumni-interview-area pb-100">
        <div class="container">
            <div class="interview-content ptb-100">
                <div class="interview">
                    <router-link to="/programoffered" class="default-btn btn">Programs Offered<i
                            class="flaticon-next"></i></router-link>
                    <router-link  to="/applynow" class="default-btn btn">Apply Now</router-link>
                </div>
            </div>
        </div>
    </div>
    <!--End Bottom Connect Area -->

</template>

<script>
export default {
    name: "ApplyNowView"
};
</script>
